import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import clsx from 'clsx';
import {
  EVBox,
  EVPaper,
  EVButton,
  EVDialog,
  EVDialogTitle,
  EVDialogContent,
  EVDialogActions,
  EVCheckbox,
  EVTypography,
} from '@eagleview/ev-comp-library';
import { MAPBOX_KEY } from 'constants.js';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import './ManualAtAdjustment.css';
import EVMapViewer from '@eagleview/mapviewer-react';
import ToastMessage from 'components/toast-message';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import { FEATURE_ENTITLEMENTS, ASSESS_MANUAL_AT_EXPLORE_OBLIQUES } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import { store } from 'store/ConfigureStore';
import * as turf from '@turf/turf';
import ErrorBoundary from 'components/ErrorBoundary';
import PendingImage from 'assets/riskImage.png';
import useResizeObserver from 'utils/useResizeObserver';
import * as action from './ManualAtAdjustment.actions';
import ManualAtHeaderBar from './manual-at-header-bar/ManualAtHeaderBar';
import ManualAtAdjustmentMapviewerToolbar from './manual-at-adjustment-mapviewer-toolbar/ManualAtAdjustmentMapviewerToolbar';
import ManualAtTopPanel from './manual-at-top-panel/ManualAtTopPanel';
import ManualAtReportStructureMapScreen from './manual-at-report-structure-map-screen/ManualAtReportStructureMapScreen';
import useStyles from './ManualAtAdjustment.styles';
import StatusBar from './manual-at-adjustment-statusbar/StatusBar';
import {
  STATUS_COMPLETED,
  INITIAL_VIEW_OVERVIEW,
  NUM_OF_RECOMMENDED_PAIRINGS,
  NUM_OF_MINIMUM_PAIRINGS,
  VERTEX_STYLE,
  KEYBOARD_SHORTCUTS,
  SKIP_STRUCTURE,
  MAIN_STRUCTURE,
} from './ManualAtAdjustment.constants';
import { getCoordsOnImage } from './ManualAtAdjustment.utils';
import useKeyboardShortcut from './use-keyboard-shortcut/useKeyboardShortcut';

const ManualAtAdjustment = ({ hideNavbar }) => {
  // utils
  const params = useParams();
  const { t } = useTranslation();
  const styles = useStyles();
  const isNewPairingInProgressRef = useRef();
  const isDeletePairedCoordinateRef = useRef();

  // local state
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [tracking, setTracking] = useState(false);
  const [localCurrentView, setLocalCurrentView] = useState({});
  const [overviewLocalCurrentView, setOverviewLocalCurrentView] = useState(INITIAL_VIEW_OVERVIEW);
  const [isOrthoMapReady, setIsOrthoMapReady] = useState(false);
  const [isOverviewMapReady, setIsOverviewMapReady] = useState(false);
  const [orthoAnnotationSelected, setOrthoAnnotationSelected] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [disableLoadMore, setDisableLoadMore] = useState(true);
  const [isClickedLoadMore, setIsClickedLoadMore] = useState(false);

  // Stores paired coordinate being edited
  const [editingPairedCoordinates, setEditingPairedCoordinates] = useState({
    coordinatePair: {},
    vertexId: '',
  });
  const [interactionObj, setInteractionObj] = useState({
    enable: false,
    type: 'tracking',
    mode: { click: true, hover: false },
    assetId: '',
    annotation: {},
  });
  const [isManualATComplete, setIsManualATComplete] = useState(false);
  // To indicate that a new pairing is in progress
  const [isNewPairingInProgress, setIsNewPairingInProgress] = useState(false);
  isNewPairingInProgressRef.current = isNewPairingInProgress;
  // To indicate that a point from paired coordinate is being deleted
  const [isDeletePairedCoordinate, setIsDeletePairedCoordinate] = useState(false);
  isDeletePairedCoordinateRef.current = isDeletePairedCoordinate;
  const [overviewAssets, setOverviewAssets] = useState({});
  const [overviewPoints, setOverviewPoints] = useState({});

  const { elRef: mapViewerRef, size: mapSize } = useResizeObserver();
  const { elRef: minImageMapViewerRef, size: minImageMapSize } = useResizeObserver();

  // redux actions
  const dispatch = useDispatch();
  const setActiveImage = (payload) => dispatch(action.setActiveImageAction(payload));
  const addCoordOne = (payload) => dispatch(action.addCoordOneAction(payload));
  const addCoordTwo = (payload) => dispatch(action.addCoordTwoAction(payload));
  const addActiveCoordPairings = (payload) => dispatch(action.addActiveCoordPairingsAction(payload));
  const handleToastClose = () => dispatch(action.closeErrorToast());
  const setActiveVertex = (payload) => dispatch(action.setActiveVertexAction(payload));
  const setView = (payload) => dispatch(action.setViewAction(payload));
  const setOverviewView = (payload) => dispatch(action.setOverviewViewAction(payload));
  const fetchOrderDetails = (payload) => dispatch(action.fetchOrderDetailsAction(payload));
  const setSelectedImagesList = (payload) => dispatch(action.setSelectedImagesListAction(payload));
  const setIsMinImageSelected = (payload) => dispatch(action.setIsMinImageSelectedAction(payload));
  const addCoordinatePairing = (payload) => dispatch(action.addCoordinatePairingAction(payload));
  const removeCoordinatePairing = (payload) => dispatch(action.removeCoordinatePairingAction(payload));
  const setSrcAction = (payload) => dispatch(action.setSrcAction(payload));
  const setSrcDst = (payload) => dispatch(action.setSrcDstAction(payload));
  const deleteOverviewPoint = (payload) => dispatch(action.deleteOverviewPointAction(payload));
  const showNotEntitledToManualATError = () => dispatch(action.showNotEntitledToManualATError());
  const fetchStructureDetailsAndImages = (payload) => dispatch(action.fetchStructureDetailsAndImages(payload));
  const fetchImagesAction = (payload) => dispatch(action.fetchImagesAction(payload));
  const saveStructureTiePoint = (payload) => dispatch(action.saveStructureTiePoint(payload));
  const setReportAndStructureTiePointData = (payload) => dispatch(action.setReportAndStructureTiePointData(payload));
  const clearReportAndStructureTiePointData = () => dispatch(action.clearReportAndStructureTiePointData());
  const setCurrentStructureAction = (payload) => dispatch(action.setCurrentStructureAction(payload));
  const setSelectedReportId = (payload) => dispatch(action.setSelectedReportIdAction(payload));
  const resetOrder = () => dispatch(action.resetOrderAction());

  // selectors
  const activeImage = useSelector((state) => get(state, 'manualAtMultiStructureReducer.activeImage'));
  const images = useSelector((state) => get(state, 'manualAtMultiStructureReducer.images', []));
  const obliqueImages = useSelector((state) => get(state, 'manualAtMultiStructureReducer.obliqueImages', []));
  const toastMessage = useSelector((state) => get(state, 'manualAtMultiStructureReducer.toastMessage'));
  const errorCode = useSelector((state) => get(state, 'manualAtMultiStructureReducer.errorCode', ''));
  const showToast = useSelector((state) => get(state, 'manualAtMultiStructureReducer.showToast'));
  const coordinatePairings = useSelector((state) => get(state, 'manualAtMultiStructureReducer.coordinatePairings'));
  const dst = useSelector((state) => get(state, 'manualAtMultiStructureReducer.dst'));
  const src = useSelector((state) => get(state, 'manualAtMultiStructureReducer.src'));
  const toastSeverity = useSelector((state) => get(state, 'manualAtMultiStructureReducer.toastSeverity'));
  const showToastPersistently = useSelector((state) => get(state, 'manualAtMultiStructureReducer.showToastPersistently'));
  const view = useSelector((state) => get(state, 'manualAtMultiStructureReducer.view'));
  const overviewView = useSelector((state) => get(state, 'manualAtMultiStructureReducer.overviewView'));
  const initialView = useSelector((state) => get(state, 'manualAtMultiStructureReducer.initialView'));
  const overviewInitialView = useSelector((state) => get(state, 'manualAtMultiStructureReducer.overviewInitialView'));
  const vertices = useSelector((state) => get(state, 'manualAtMultiStructureReducer.vertices'));
  const polygon = useSelector((state) => get(state, 'manualAtMultiStructureReducer.polygon'));
  const orthoImage = useSelector((state) => get(state, 'manualAtMultiStructureReducer.orthoImage'));
  const entitlements = useSelector((state) => get(state, 'entitleUserReducer.entitlements'));
  const hideUI = useSelector((state) => get(state, 'manualAtMultiStructureReducer.hideUI'));
  const selectedImagesList = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedImagesList'));
  const isMinImageSelected = useSelector((state) => get(state, 'manualAtMultiStructureReducer.isMinImageSelected'));
  const taskStateId = useSelector((state) => get(state, 'manualAtMultiStructureReducer.taskStateId', ''));
  const activeVertex = useSelector((state) => get(state, 'manualAtMultiStructureReducer.activeVertex'));
  const completedVertices = useSelector((state) => get(state, 'manualAtMultiStructureReducer.completedVertices'));
  const status = useSelector((state) => get(state, 'manualAtMultiStructureReducer.orderDetails.status'));
  const readOnlyMode = useSelector((state) => get(state, 'manualAtMultiStructureReducer.readOnlyMode', false));
  const toastDuration = useSelector((state) => get(state, 'manualAtMultiStructureReducer.toastDuration', 10000));
  const selectedStructure = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedStructure', {}));
  const reportDetails = useSelector((state) => get(state, 'manualAtMultiStructureReducer.reportDetails', {}));
  const structuresDetails = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structuresDetails', {}));
  const structureReportMap = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structureReportMap', {}));
  const structures = useSelector((state) => get(state, 'manualAtMultiStructureReducer.structures', []));
  const capturedStructures = useSelector((state) => get(state, 'manualAtMultiStructureReducer.capturedStructures', []));
  const capturedStructuresCount = useSelector((state) => get(state, 'manualAtMultiStructureReducer.capturedStructuresCount', 0));
  const selectedReportId = useSelector((state) => get(state, 'manualAtMultiStructureReducer.selectedReportId', ''));
  const loading = useSelector((state) => get(state, 'manualAtMultiStructureReducer.loading', ''));
  const allReportsFetched = useSelector((state) => get(state, 'manualAtMultiStructureReducer.allReportsFetched', false));

  const isEntitledToManualAT = isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_MANUAL_AT_VIA_OPS_PRIME)
                            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME)
                            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME)
                            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS);
  const canManage = (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_VIA_OPS_PRIME)
                            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_ESCALATION_VIA_OPS_PRIME)
                            || isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_MANUAL_AT_TIE_POINTS))
                            && !readOnlyMode;
  const disableComplete = coordinatePairings.length < NUM_OF_MINIMUM_PAIRINGS || (src && Object.keys(src).length !== selectedImagesList.length);
  const selectedStructureId = selectedStructure.structureID || '';
  const structureNumber = (selectedStructure.structureID
    && structures.findIndex((structure) => structure.structureID === selectedStructure.structureID) + 1) || '';
  const nextStructureIndex = (selectedStructure.structureID
    && capturedStructures.findIndex((structure) => structure.structureID === selectedStructure.structureID) + 1) || '';
  const isLastStructure = nextStructureIndex === capturedStructures.length;
  const showCompleteButton = isLastStructure && isMinImageSelected;

  const isAssessManualAtExploreObliquesEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_MANUAL_AT_EXPLORE_OBLIQUES,
    false,
  ));

  const allImages = [...images, ...obliqueImages];

  const setOverviewTracking = (setVal) => {
    setInteractionObj({
      ...interactionObj,
      enable: setVal,
      type: 'tracking',
      mode: { click: true, hover: false },
      assetId: '',
      annotation: {},
    });
  };

  useEffect(() => {
    if (reportDetails[selectedReportId]) {
      setReportAndStructureTiePointData({ selectedReport: selectedReportId, structureId: selectedStructureId });
    } else if (polygon.length > 0) {
      clearReportAndStructureTiePointData();
    }
    const loadMoreOblique = get(structuresDetails[selectedStructureId], 'loadOblique', false);
    setDisableLoadMore(!loadMoreOblique);
  }, [reportDetails[selectedReportId], structuresDetails[selectedStructureId]]);

  useEffect(() => {
    if (status === STATUS_COMPLETED) {
      setIsManualATComplete(true);
      setTracking(false);
      setOverviewTracking(false);
    }
  }, [status]);

  useEffect(() => {
    if (!isEntitledToManualAT) {
      showNotEntitledToManualATError();
    }
  }, [isEntitledToManualAT]);

  useEffect(() => {
    if (isEntitledToManualAT) {
      fetchOrderDetails({ orderId: params.orderId });
    }
    return () => {
      resetOrder();
    };
  }, []);

  useEffect(() => {
    if (isOrthoMapReady && isMinImageSelected) {
      setTracking(true);
    } else {
      setTracking(false);
    }
    if (isOverviewMapReady && isMinImageSelected) {
      setOverviewTracking(true);
    } else {
      setOverviewTracking(false);
    }
  }, [isMinImageSelected]);

  useEffect(() => {
    if (allReportsFetched && selectedStructureId && !(get(structuresDetails[selectedStructureId], 'images[0].url', ''))) {
      fetchStructureDetailsAndImages({ orderId: params.orderId, structureId: selectedStructureId });
    }
    if (structureReportMap[selectedStructureId] && !selectedReportId) setSelectedReportId(structureReportMap[selectedStructureId]);
  }, [selectedStructureId, allReportsFetched]);

  useEffect(() => {
    if (mapViewerRef.current && mapViewerRef.current.resizeMap) {
      mapViewerRef.current.resizeMap();
    }
  }, [window.innerWidth, mapSize.width, mapSize.height]);

  useEffect(() => {
    if (minImageMapViewerRef.current && minImageMapViewerRef.current.resizeMap) {
      minImageMapViewerRef.current.resizeMap();
    }
  }, [window.innerWidth, minImageMapSize.width, minImageMapSize.height]);

  const confirmSave = () => {
    saveStructureTiePoint({
      taskStateId,
      orderId: params.orderId,
      structureId: selectedStructureId,
      isLastStructure,
      nextStructureNumber: capturedStructures[nextStructureIndex] || {},
      omBody: {
        status: STATUS_COMPLETED,
        pairing: coordinatePairings.map((data) => ({
          roofPointCoordinates: {
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(data.dst.x.toFixed(13)), parseFloat(data.dst.y.toFixed(13)), parseFloat(data.dst.z.toFixed(13))],
            },
            srid: 4326,
          },
          imagePoints: Object.keys(data.src).map((srcImage) => ({
            pixelCoords: [parseInt(data.src[srcImage].pixel.x, 10), parseInt(data.src[srcImage].pixel.y, 10)],
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(data.src[srcImage].lonLat.lon.toFixed(13)), parseFloat(data.src[srcImage].lonLat.lat.toFixed(13))],
            },
            image: srcImage,
          })),
        })),
        selectedImages: selectedImagesList,
        reportID: structureReportMap[selectedStructureId],
      },
    });
  };

  const handleCloseDialog = () => setConfirmDialogOpen(false);

  const saveStructureTiePointing = () => {
    confirmSave();
    setConfirmDialogOpen(false);
  };

  const handleSaveTiePoint = () => {
    if (coordinatePairings.length < NUM_OF_RECOMMENDED_PAIRINGS) {
      setConfirmDialogOpen(true);
    } else {
      saveStructureTiePointing();
    }
  };

  const onMapViewUpdated = (currentView) => {
    const { bound, ...newView } = currentView;
    setLocalCurrentView({ ...newView });
  };

  const onOverviewMapViewUpdated = (currentView) => {
    const { bound, ...newView } = currentView;
    setOverviewLocalCurrentView({ ...newView });
  };

  useEffect(() => {
    if (window.ev_vp && window.ev_vp.toggleSidebarHide && hideNavbar) {
      window.ev_vp.toggleSidebarHide();
    } else if (window.ev_vp && window.ev_vp.toggleSidebarShow && !hideNavbar) {
      window.ev_vp.toggleSidebarShow();
    }
  }, []);

  useEffect(() => {
    // Set src based on whether the selected dst is already a paired point
    if (completedVertices.includes(orthoAnnotationSelected.id) && !isEditing) {
      const coordPair = coordinatePairings.filter((pairing) => orthoAnnotationSelected.id === pairing.id)[0];
      const plottedOverviewPoints = coordPair && coordPair.src;
      setSrcAction(plottedOverviewPoints);
    } else {
      setSrcAction(undefined);
    }
  }, [orthoAnnotationSelected]);

  const handleMapClick = (e) => {
    if (e.state === 'tracked') {
      const eventType = get(e, 'event.type', false);
      const asset = get(e, 'annotation.annotation.id', false);
      const assetType = get(e, 'annotation.assetId', false);
      if (eventType !== 'click' || !asset || assetType !== 'vertices') return;
      const coords = get(e, 'annotation.annotation.geometries[0].coordinates');
      setActiveVertex(asset);
      addCoordOne({
        x: coords[0],
        y: coords[1],
        z: coords[2],
      });
      setOrthoAnnotationSelected(get(e, 'annotation.annotation', {}));
    }
  };

  const handleOverviewPointAdd = () => {
    if (src && dst && selectedImagesList.length > 0) {
      const dstId = JSON.stringify([dst.x, dst.y, dst.z]);
      if (!completedVertices.includes(dstId) && selectedImagesList.every((k) => Object.keys(src).includes(k))) {
        const coordinateIndex = findIndex(vertices[0].data, (vertex) => vertex.id === JSON.stringify(Object.values(dst)));
        addActiveCoordPairings({ coordinateIndex, id: dstId });
        setEditingPairedCoordinates({
          coordinatePair: {},
          vertexId: '',
        });
        setIsNewPairingInProgress(false);
        setIsEditing(false);
        setIsDeletePairedCoordinate(false);
        setOrthoAnnotationSelected({});
        setTracking(true);
      }
    }
  };

  useEffect(() => {
    handleOverviewPointAdd();
    // To handle the scenario where the src is set with point on active image due to mapViewer returning 'completed'
    // event state twice after completing editing a point
    if (!dst && src && selectedImagesList.length > 0 && !selectedImagesList.every((k) => Object.keys(src).includes(k))) {
      setSrcAction(undefined);
    }
  }, [src]);

  useEffect(() => {
    if (activeImage && !isEmpty(get(activeImage, 'corners', {}))) {
      const overviewVertices = {
        assetType: 'annotation',
        uuid: 'overview-vertices',
        data:
          uniqBy((src && dst && src[activeImage.image_name]
            ? [
              {
                id: JSON.stringify(src[activeImage.image_name]),
                geometries: [
                  {
                    type: 'Point',
                    coordinates: [
                      src[activeImage.image_name].lonLat.lon,
                      src[activeImage.image_name].lonLat.lat,
                    ],
                  },
                ],
                properties: {
                  annotationType: 'point',
                  showLabel: true,
                  name: findIndex(vertices[0].data, (vertex) => vertex.id === activeVertex),
                  orthoId: JSON.stringify([dst.x, dst.y, dst.z]),
                },
                style: {
                  fillColor: '#000000',
                  fillOpacity: 0.5,
                },
              },
            ]
            : []).concat(
            (coordinatePairings.length > 0) ? coordinatePairings.map((coordinatePairing) => coordinatePairing.src
            && coordinatePairing.src[activeImage.image_name] && coordinatePairing.dst && {
              id: JSON.stringify(coordinatePairing.src[activeImage.image_name]),
              geometries: [
                {
                  type: 'Point',
                  coordinates: [
                    coordinatePairing.src[activeImage.image_name].lonLat.lon,
                    coordinatePairing.src[activeImage.image_name].lonLat.lat,
                  ],
                },
              ],
              properties: {
                annotationType: 'point',
                showLabel: true,
                name: coordinatePairing.index,
                orthoId: JSON.stringify([coordinatePairing.dst.x, coordinatePairing.dst.y, coordinatePairing.dst.z]),
              },
              style: VERTEX_STYLE.COMPLETED,
            }) : [],
          ), 'id'),
      };
      setOverviewPoints(overviewVertices);
    }
  }, [activeImage, vertices, src]);

  useEffect(() => {
    if (activeImage && !isEmpty(get(activeImage, 'corners', {}))) {
      const overviewImageAsset = {
        uuid: activeImage.image_name,
        assetType: 'image',
        corners: activeImage.corners,
        url: activeImage.url,
        beforeAsset: overviewPoints,
      };
      setOverviewAssets(overviewImageAsset);
    }
  }, [activeImage, overviewPoints]);

  const handleEditing = (annotation, image) => {
    const lon = annotation.geometries[0].coordinates[0];
    const lat = annotation.geometries[0].coordinates[1];
    if (dst) {
      addCoordTwo(getCoordsOnImage(image, lon, lat));
    }
  };

  const handleEditAbort = (sourcePoints) => {
    if (sourcePoints && selectedImagesList.every((k) => Object.keys(sourcePoints).includes(k))) {
      addCoordinatePairing(editingPairedCoordinates);
      setTracking(true);
    }
    setOverviewTracking(true);
  };

  const handleOverviewMapClick = (e) => {
    const dstPoint = get(store.getState(), 'manualAtMultiStructureReducer.dst');
    const sourcePoints = get(store.getState(), 'manualAtMultiStructureReducer.src');
    const image = get(store.getState(), 'manualAtMultiStructureReducer.activeImage');
    const completedVerticesFromStore = get(store.getState(), 'manualAtMultiStructureReducer.completedVertices');
    const coordinatePairingsFromStore = get(store.getState(), 'manualAtMultiStructureReducer.coordinatePairings');
    const verticesStore = get(store.getState(), 'manualAtMultiStructureReducer.vertices');
    if (!image) return;
    const { lat, lon } = get(e, 'event.data.lonLat', { lon: 0, lat: 0 });
    const clickIsInside = turf.booleanPointInPolygon(turf.point([lon, lat]), image.polygon);
    if (!clickIsInside) return;
    if (e.state === 'tracked') {
      const { annotation: annotationObject } = e;
      if (Object.keys(annotationObject).length > 0) {
        const annotationToEdit = annotationObject;
        const orthoIdOfAnnotationToEdit = annotationToEdit.annotation.properties.orthoId;
        if ((!isNewPairingInProgressRef.current && !isDeletePairedCoordinateRef.current)
              || ((isNewPairingInProgressRef.current || isDeletePairedCoordinateRef.current) && sourcePoints
                  && Object.keys(sourcePoints).some((srcImage) => annotationToEdit.annotation.id === JSON.stringify(sourcePoints[srcImage])))) {
          if (completedVerticesFromStore.includes(orthoIdOfAnnotationToEdit)) {
            const coordinatePairToEdit = coordinatePairingsFromStore.filter((pairing) => pairing.id === orthoIdOfAnnotationToEdit)[0];
            if (Object.keys(coordinatePairToEdit.src).some((srcImage) => (
              annotationToEdit.annotation.id === JSON.stringify(coordinatePairToEdit.src[srcImage])))) {
              setOrthoAnnotationSelected(verticesStore[0].data.filter((vertex) => vertex.id === orthoIdOfAnnotationToEdit));
              setTracking(false);
              setEditingPairedCoordinates({
                coordinatePair: coordinatePairToEdit,
                vertexId: orthoIdOfAnnotationToEdit,
              });
              setSrcDst({ src: coordinatePairToEdit.src, dst: coordinatePairToEdit.dst });
              setActiveVertex(orthoIdOfAnnotationToEdit);
              removeCoordinatePairing(orthoIdOfAnnotationToEdit);
            }
          }
          setIsEditing(true);
          setInteractionObj({
            ...interactionObj,
            enable: true,
            type: 'editing',
            mode: { edit: true },
            assetId: annotationToEdit.assetId,
            annotation: annotationToEdit.annotation,
          });
        }
      } else if (((dstPoint && !sourcePoints) || (dstPoint && sourcePoints && !Object.keys(sourcePoints).includes(image.image_name)))) {
        setIsNewPairingInProgress(true);
        setTracking(false);
        addCoordTwo(getCoordsOnImage(image, lon, lat));
      }
    }
    if (e.state === 'completed') {
      const { annotation } = e;
      setIsEditing(false);
      setOverviewTracking(true);
      handleEditing(annotation, image);
    }
    if ((e.state === 'abort') && interactionObj.type === 'editing') {
      handleEditAbort(sourcePoints);
    }
  };

  const handleImageSelection = (image) => {
    if (!isMinImageSelected && image.available && canManage) {
      if (selectedImagesList.includes(image.image_name)) {
        setSelectedImagesList(selectedImagesList.filter((i) => i !== image.image_name));
      } else if (selectedImagesList.length < 2) {
        setSelectedImagesList([...selectedImagesList, image.image_name]);
      } else {
        const clonedSelectedImages = [...selectedImagesList];
        clonedSelectedImages[1] = image.image_name;
        setSelectedImagesList(clonedSelectedImages);
      }
    } else if (selectedImagesList.includes(image.image_name) && image.available) {
      setActiveImage({ image });
      setOverviewTracking(true);
      setIsEditing(false);
    }
  };

  const handleNext = () => {
    const orderedSelectedImage = allImages.filter((image) => selectedImagesList.includes(image.image_name)).map((i) => i.image_name);
    setIsMinImageSelected(true);
    setSelectedImagesList(orderedSelectedImage);
    setActiveImage({ image: allImages.find((img) => img.image_name === orderedSelectedImage[0]) });
  };

  const handleNextImage = () => {
    const currentImageIndex = selectedImagesList.indexOf(activeImage.image_name);
    const nextImage = selectedImagesList[(currentImageIndex + 1) % selectedImagesList.length];
    setActiveImage({ image: allImages.find((img) => img.image_name === nextImage) });
    setOverviewTracking(true);
    setIsEditing(false);
  };

  const handleDeleteOverviewPoint = () => {
    if (dst && src && isEditing) {
      if (Object.keys(src).length === 1) {
        // Unset isDeletePairedCoordinate when a last point in a pairing is deleted
        setIsDeletePairedCoordinate(false);
        // Enable ortho map tracking when the last point corresponding to a dst is being deleted so that any other dst point
        // can be selected when last point in a pairing is deleted
        setTracking(true);
        // set isNewPairingInProgressRef to false when the last point is deleted while a new active pairing was in progress
        if (isNewPairingInProgress) {
          setIsNewPairingInProgress(false);
        }
      } else {
        // set isDeletePairedCoordinate to true when a point in src is being deleted so that it can be used to handle the
        // scenario where the selecting overview point which is not a corresponding point of dst is disabled
        setIsDeletePairedCoordinate(true);
      }
      setIsEditing(false);
      deleteOverviewPoint();
      setOverviewTracking(true);
    }
  };

  const handleMapReady = () => {
    setIsOrthoMapReady(true);
    if (isMinImageSelected) {
      setTracking(true);
    }
  };

  const handleOverviewMapReady = () => {
    setIsOverviewMapReady(true);
    if (isMinImageSelected) {
      setOverviewTracking(true);
    }
  };

  const deletePoint = () => {
    if (canManage && !isManualATComplete) {
      handleDeleteOverviewPoint();
    }
  };
  useKeyboardShortcut(
    KEYBOARD_SHORTCUTS.deletePoint,
    () => { deletePoint(); },
    { inOrder: true },
  );

  useKeyboardShortcut(
    KEYBOARD_SHORTCUTS.deletePointBackspace,
    () => { deletePoint(); },
    { inOrder: true },
  );

  const handleNextButtonClick = () => {
    if (!isMinImageSelected) {
      handleNext();
    } else if (status === STATUS_COMPLETED) {
      clearReportAndStructureTiePointData();
      setCurrentStructureAction(capturedStructures[nextStructureIndex] || {});
    } else {
      handleSaveTiePoint();
    }
  };

  const loadMoreOblique = () => {
    fetchImagesAction({ orderId: params.orderId, structureId: selectedStructureId, imageType: 'EXPLORE_OBLIQUES' });
    setIsClickedLoadMore(true);
  };

  return (
    <>
      {!hideUI && isEntitledToManualAT && (
      <EVBox height="100%">
        <ManualAtTopPanel
          orderId={params.orderId}
          showResetButton={canManage && !isManualATComplete}
          showEscalateButton={canManage && !isManualATComplete}
          disableEscalateButton={isMinImageSelected && src && Object.keys(src).length !== selectedImagesList.length}
          showSkipStructureButton={canManage && !isManualATComplete}
          disableSkipStructureButton={selectedStructureId === MAIN_STRUCTURE || !!loading[SKIP_STRUCTURE]}
          nextStructure={capturedStructures[nextStructureIndex] || {}}
          isLastStructure={isLastStructure}
        />
        <EVBox clone py={2} height="100%">
          <EVPaper className={styles.paper}>
            {!structureReportMap[selectedStructureId] && <ManualAtReportStructureMapScreen disableRadio={status === STATUS_COMPLETED} />}
            {structureReportMap[selectedStructureId] && (
            <EVBox px={3} className={styles.container}>
              <ManualAtHeaderBar
                header={t('manualAtAdjustment.manualAtAdjustment')}
                subheader={t('manualAtAdjustment.structureNumber').replace('$num$', structureNumber).replace('$count$', capturedStructuresCount)}
                showNextButton={!showCompleteButton}
                disableNextButton={selectedImagesList.length < 2 || (isMinImageSelected && disableComplete)}
                handleNextClick={handleNextButtonClick}
                showCompleteButton={showCompleteButton && status !== STATUS_COMPLETED}
                disableCompleteButton={disableComplete}
                handleCompleteButtonClick={handleNextButtonClick}
                showFacetImageButton
                orderId={params.orderId}
              />
              {canManage && !isManualATComplete && (
              <EVBox display="flex" my={2}>
                {!isMinImageSelected && (<EVTypography variant="body2">{t('manualAtAdjustment.step1')}</EVTypography>)}
              </EVBox>
              )}
              <EVBox display="flex" height="70%">
                <EVBox
                  flex="1 1 50%"
                  maxWidth="50%"
                  height="100%"
                  position="relative"
                  bgcolor="black"
                  className={clsx(styles.crosshair, { [styles.orthoImage]: !isMinImageSelected })}
                  data-testid="ortho-image-display-box"
                  mr={1}
                >
                  <EVMapViewer
                    view={view}
                    ref={mapViewerRef}
                    onViewUpdated={onMapViewUpdated}
                    assets={
                    isEmpty(orthoImage)
                      ? vertices
                      : [
                        { ...orthoImage[0], beforeAsset: polygon[0] },
                        ...(polygon.length > 0 && vertices.length > 0) ? [{ ...polygon[0], beforeAsset: vertices[0] }] : [],
                        ...vertices,
                      ]
                    }
                    baseMap="blank"
                    interaction={{
                      enable: tracking,
                      type: 'tracking',
                      handler: handleMapClick,
                    }}
                    initialConfig={{
                      style: { backgroundColor: '#000' },
                      mapBoxKey: MAPBOX_KEY,
                    }}
                    onMapReady={() => { if (canManage && !isManualATComplete) handleMapReady(); }}
                  />
                  <ManualAtAdjustmentMapviewerToolbar
                    fitImage={() => setView({ ...initialView, rotation: localCurrentView.rotation })}
                    rotateImage={() => setView({ ...localCurrentView, rotation: localCurrentView.rotation - 90 })}
                    zoomInClick={() => setView({ ...localCurrentView, zoom: localCurrentView.zoom + 1 })}
                    zoomOutClick={() => setView({ ...localCurrentView, zoom: localCurrentView.zoom - 1 })}
                  />
                </EVBox>
                {isMinImageSelected && (
                <EVBox
                  flex="1 1 50%"
                  maxWidth="50%"
                  height="100%"
                  position="relative"
                  bgcolor="black"
                  className={styles.crosshair}
                  data-testid="overview-image-display-box"
                  ml={1}
                >
                  <EVMapViewer
                    view={(activeImage && overviewView[activeImage.image_name]) || INITIAL_VIEW_OVERVIEW}
                    ref={minImageMapViewerRef}
                    onViewUpdated={onOverviewMapViewUpdated}
                    assets={activeImage ? [overviewAssets, overviewPoints] : []}
                    baseMap="blank"
                    interaction={{
                      ...interactionObj,
                      handler: handleOverviewMapClick,
                    }}
                    initialConfig={{
                      style: { backgroundColor: '#000' },
                      mapBoxKey: MAPBOX_KEY,
                    }}
                    onMapReady={() => { if (canManage && !isManualATComplete) handleOverviewMapReady(); }}
                  />
                  <ManualAtAdjustmentMapviewerToolbar
                    showDelete={(canManage && !isManualATComplete)}
                    deletePoint={handleDeleteOverviewPoint}
                    fitImage={() => setOverviewView({
                      ...overviewInitialView,
                      rotation: overviewLocalCurrentView.rotation,
                    })}
                    rotateImage={() => setOverviewView({
                      ...overviewLocalCurrentView,
                      rotation: overviewLocalCurrentView.rotation - 90,
                    })}
                    zoomInClick={() => setOverviewView({
                      ...overviewLocalCurrentView,
                      zoom: overviewLocalCurrentView.zoom + 1,
                    })}
                    zoomOutClick={() => {
                      if (overviewLocalCurrentView.zoom > 1) {
                        setOverviewView({
                          ...overviewLocalCurrentView,
                          zoom: overviewLocalCurrentView.zoom - 1,
                        });
                      }
                    }}
                  />
                </EVBox>
                )}
              </EVBox>
              <EVBox height="70px" display="flex">
                <EVBox flex="1 1 50%" textAlign="center" className={styles.orthoImage}>
                  {!isMinImageSelected
                    ? <span>{t('manualAtAdjustment.orthographicImage')}</span>
                    : <span className={styles.imageType}>{t('manualAtAdjustment.roofReportImage')}</span>}
                  {dst && (
                  <EVBox>
                    <span>{`${t('manualAtAdjustment.coordinates')} X: ${dst.x.toFixed(5)}, Y: ${dst.y.toFixed(5)}, Z:${dst.z.toFixed(5)}`}</span>
                  </EVBox>
                  )}
                </EVBox>
                {isMinImageSelected && (
                <EVBox flex="1 1 50%" textAlign="center">
                  <span className={styles.imageType}>{get(activeImage, 'image_name', '')}</span>
                  {activeImage && get(src, `${activeImage.image_name}`, false) && (
                  <EVBox>
                    <span>
                      {`${t('manualAtAdjustment.coordinates')} X: ${Math.trunc(
                        get(src, `${activeImage.image_name}`).pixel.x,
                      )}, Y: ${Math.trunc(get(src, `${activeImage.image_name}`).pixel.y)}`}
                    </span>
                  </EVBox>
                  )}
                </EVBox>
                )}
              </EVBox>
              {isMinImageSelected && (
              <EVBox flex="1 1 auto" display="flex" justifyContent="flex-end" alignItems="center">
                <EVButton className={styles.button} variant="contained" color="primary" data-testid="next-image-button" onClick={handleNextImage}>
                  {t('manualAtAdjustment.nextImage')}
                </EVButton>
              </EVBox>
              )}
              { isAssessManualAtExploreObliquesEnabled && isOrthoMapReady && !isMinImageSelected
              && (
              <EVBox flex="1 1 auto" display="flex" justifyContent="flex-end">
                <EVButton
                  color="primary"
                  className={styles.button}
                  variant="contained"
                  onClick={() => { loadMoreOblique(); }}
                  size="small"
                  disabled={isClickedLoadMore || disableLoadMore}
                >
                  {t('manualAtAdjustment.loadObliques')}
                </EVButton>
              </EVBox>
              )}
              <EVBox flex="1 1 auto" height="30%">
                {allImages.map((image, i) => (
                  <EVBox key={image.image_name} mr={2} display="inline-block">
                    <EVBox display="inline-block">
                      {((!isMinImageSelected && canManage && !isManualATComplete) || selectedImagesList.includes(image.image_name)) && (
                      <EVBox clone p={0}>
                        <EVCheckbox
                          color="primary"
                          checked={selectedImagesList.includes(image.image_name)}
                          disabled={image.available ? (isMinImageSelected || !canManage || (i === 0)) : true}
                          onChange={() => handleImageSelection(image)}
                        />
                      </EVBox>
                      )}
                      <EVBox clone mr={2}>
                        <EVButton
                          color={(isMinImageSelected && activeImage && image.image_name === activeImage.image_name) ? 'primary' : 'default'}
                          onClick={() => handleImageSelection(image)}
                          size="small"
                          disabled={!image.available || !canManage || (i === 0) || isMinImageSelected || isManualATComplete}
                          data-testid="image-button"
                        >
                          {image.image_name}
                        </EVButton>
                      </EVBox>
                      <EVBox>
                        <img src={image.available ? image.url : PendingImage} className={styles.images} alt={image.image_name} />
                      </EVBox>
                    </EVBox>
                  </EVBox>
                ))}
              </EVBox>
            </EVBox>
            )}
          </EVPaper>
        </EVBox>
        {isMinImageSelected && (
        <ErrorBoundary
          componentName="Manual AT: Status Bar"
          alertMsg={t('manualAtAdjustment.statusBarCrash')}
        >
          <StatusBar />
        </ErrorBoundary>
        )}
      </EVBox>
      )}

      <EVDialog open={confirmDialogOpen} onClose={handleCloseDialog}>
        <EVDialogTitle>{t('manualAtAdjustment.saveDialogTitle')}</EVDialogTitle>
        <EVDialogContent data-testid="complete-dialog-content">
          <div>
            <p>
              {t('manualAtAdjustment.pairingsLessThanRecommendedWarningLine1')}
            </p>
            <p>
              {/* eslint-disable-next-line */}
              {NUM_OF_RECOMMENDED_PAIRINGS} {t('manualAtAdjustment.pairingsLessThanRecommendedWarningLine2a')} {t('manualAtAdjustment.pairingsLessThanRecommendedWarningLine2bYouHave')} {coordinatePairings.length} {t('manualAtAdjustment.pairingsLessThanRecommendedWarningLine2bCurrently')}
            </p>
          </div>
        </EVDialogContent>
        <EVDialogActions>
          <EVButton color="primary" variant="contained" onClick={handleCloseDialog}>
            {(coordinatePairings.length < NUM_OF_RECOMMENDED_PAIRINGS) ? t('no') : t('cancel')}
          </EVButton>
          <EVButton
            color="primary"
            variant="contained"
            onClick={saveStructureTiePointing}
          >
            {t('yes')}
          </EVButton>
        </EVDialogActions>
      </EVDialog>
      <ToastMessage
        alertMsg={`${t(toastMessage)} ${errorCode ? `${t('home.referCode')} ${errorCode}` : ''}`}
        severity={toastSeverity}
        open={!!showToast}
        onClose={showToastPersistently ? () => {
        // Empty Block
        } : handleToastClose}
        autoHideDuration={toastDuration}
      />
    </>
  );
};

ManualAtAdjustment.propTypes = {
  hideNavbar: bool,
};

ManualAtAdjustment.defaultProps = {
  hideNavbar: false,
};

export default ManualAtAdjustment;
