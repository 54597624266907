import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  EVEditOutlined,
  EVIconButton,
  EVTooltip,
  EVCircularProgress,
  EVDialog,
  EVDialogTitle,
  EVDialogContent,
  EVDialogActions,
  EVButton,
  EVTextField,
  EVBox,
} from '@eagleview/ev-comp-library';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_CLAIM_NUMBER } from 'layout/adjuster/Adjuster.constants';
import { useTranslation } from 'react-i18next';
import { updateClaimNumberAction } from '../../Adjuster.actions';
import useStyles from './EditClaimNumber.styles';

const EditClaimNumber = ({ orderID, claimID, disableEditClaimNumber }) => {
  const { t } = useTranslation();
  const [selectedClaimId, setSelectedClaimId] = useState(claimID);
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.adjusterReducer.loading);

  const handleIconClick = () => {
    setDialogOpen(true);
  };

  const handleSubmit = () => {
    setDialogOpen(false);
    setSelectedClaimId(claimID);
    dispatch(updateClaimNumberAction({ orderId: orderID, ClaimNumber: selectedClaimId.trim() }));
  };

  const setClaimNumber = (value) => {
    if (value.startsWith(' ')) return;
    setSelectedClaimId(value);
  };

  const handleBlur = () => {
    setSelectedClaimId(selectedClaimId.trim());
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setSelectedClaimId(claimID);
  };

  return (
    <EVBox className={classes.datePickerWrapper}>
      <EVTooltip
        title={disableEditClaimNumber ? t('adjuster.RoofReportUpdateProgressMessage') : t('adjuster.editClaimNumberTip')}
      >
        {
          loading[UPDATE_CLAIM_NUMBER] ? (
            <EVCircularProgress size={32} className={classes.editButton} />
          ) : (
            <EVIconButton
              data-testid="edit-claim-number-pencil"
              onClick={handleIconClick}
              disabled={disableEditClaimNumber}
              className={classes.editButton}
            >
              <EVEditOutlined />
            </EVIconButton>
          )
        }
      </EVTooltip>

      <EVBox>
        <EVDialog data-testid="dialog-box" open={dialogOpen} onClose={handleCancel}>
          <EVDialogTitle>{t('adjuster.tab.changeClaimNumber')}</EVDialogTitle>
          <EVDialogContent className={classes.dialogBox}>
            <EVTextField
              data-testid="claimNumber-input"
              className={classes.inputBox}
              id="claimNumber"
              fullWidth
              placeholder={t('adjuster.tab.claimNumber')}
              value={selectedClaimId}
              onChange={(e) => setClaimNumber(e.target.value)}
              onBlur={handleBlur}
              error={!selectedClaimId.length}
              helperText={!selectedClaimId.length ? t('adjuster.tab.claimNumberEmptyErrorText') : ''}
              variant="outlined"
            />
          </EVDialogContent>
          <EVDialogActions>
            <EVButton
              data-testid="cancel-button"
              value="cancel"
              onClick={handleCancel}
            >
              {t('cancel')}
            </EVButton>
            <EVButton
              data-testid="submit-button"
              variant="contained"
              onClick={handleSubmit}
              color="primary"
              disabled={!selectedClaimId.length}
            >
              {t('submit')}
            </EVButton>
          </EVDialogActions>
        </EVDialog>
      </EVBox>
    </EVBox>
  );
};

EditClaimNumber.propTypes = {
  orderID: PropTypes.string.isRequired,
  claimID: PropTypes.string.isRequired,
  disableEditClaimNumber: PropTypes.bool.isRequired,
};

export default EditClaimNumber;
