import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import {
  EVChevronLeft,
  EVChevronRight,
  EVDialog,
  EVBox,
  EVIconButton,
  EVClear,
  EVToolbarPanel,
  EVAdd,
  EVRemove,
  EVFullSize,
  EVCropFree,
  EVRotateRight,
  useTheme,
  EVPaperClipIcon,
} from '@eagleview/ev-comp-library';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ASSESS_IMAGES_API_ENDPOINT, MAPBOX_KEY } from 'constants.js';
import * as sharedUtil from 'utils/utils';
import EVMapViewer from '@eagleview/mapviewer-react';
import * as action from 'layout/adjuster/Adjuster.actions';
import EntitledComponent from 'components/EntitledComponent';
import { FEATURE_ENTITLEMENTS, ASSESS_SOFT_METALS_IN_REPORT, ASSESS_SKYDIO_IMAGERY_IN_REPORT } from 'layout/entitleUser/EntitleUser.constants';
import { isEntitled } from 'utils/auth.utils';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  MIN_IMAGE_ROW_INDEX,
  MAX_IMAGE_ROW_INDEX,
  MAX_IMAGES_BY_ROW,
  KEY_CODE,
  DEFAULT_MAX_ZOOM,
} from '../Adjuster.constants';
import useStyles from './FacetImagesDialog.styles';
import * as selectors from '../workflow-panel/WorkflowPanel.selectors';
import ReportSwitch from '../statusbar/ReportSwitch';

const FacetImagesDialog = ({
  open, handleClose, facetAliasName, isAnomalyImages, facetId, facetDecision, facetIncludeInReport,
}) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  // actions
  const dispatch = useDispatch();
  const fetchFacetImageMetadata = (payload) => dispatch(action.fetchFacetImageMetaAction(payload));
  const fetchSoftMetalImagesIncludedInReport = (payload) => dispatch(action.fetchSoftMetalImagesIncludedInReportAction(payload));
  const fetchSkydioImagesIncludedInReport = (payload) => dispatch(action.fetchSkydioImagesIncludedInReportAction(payload));
  const saveSoftMetalImageReportInclusion = (payload) => dispatch(action.saveSoftMetalImageReportInclusionAction(payload));
  const deleteSoftMetalImageReportInclusion = (payload) => dispatch(action.deleteSoftMetalImageReportInclusionAction(payload));
  const saveSkydioImageReportInclusion = (payload) => dispatch(action.saveSkydioImageReportInclusionAction(payload));
  const deleteSkydioImageReportInclusion = (payload) => dispatch(action.deleteSkydioImageReportInclusionAction(payload));
  const saveFacetReportInclusion = (payload) => dispatch(action.saveFacetReviewDecisionAction(payload));
  const refreshTokenCompleted = () => dispatch(action.updateAccessTokenCompletedAction());

  // constants
  const defaultView = { lonLat: { lat: 0, lon: 0 }, zoom: 1, rotation: 0 };
  // selectors
  const softMetalImages = useSelector(selectors.softMetalImagesSelector);
  const anomalyImages = useSelector(selectors.anomalyImagesSelector);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const enableSoftMetalReportInclusion = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_SOFT_METALS_IN_REPORT, false));
  const enableSkydioImageryReportInclusion = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_SKYDIO_IMAGERY_IN_REPORT, false));
  const reportIncludedSoftMetalImages = useSelector((state) => state.adjusterReducer.reportIncludedSoftMetalImages);
  const reportIncludedSkydioImages = useSelector((state) => state.adjusterReducer.reportIncludedSkydioImages);
  const roofId = useSelector((state) => state.adjusterReducer.roofId);

  // local state
  const [selectedFacetImageIndex, setSelectedFacetImageIndex] = useState({
    x: MIN_IMAGE_ROW_INDEX,
    y: MIN_IMAGE_ROW_INDEX,
  });
  const [assets, setAssets] = useState([]);
  const [keyPress, setKeyPress] = useState();
  const [view, setView] = useState(defaultView);
  const [localView, setLocalView] = useState(defaultView);
  const mapViewerRef = useRef();
  const { id: orderId } = useParams();

  // derived values
  const MAPVIEWER_MAX_ZOOM = useSelector((state) => {
    const groups = get(state, 'adjusterReducer.assessAppSettings.groups', []);
    const zoomGroup = groups.find((group) => group.groupName === 'ZOOM_LEVEL');
    return parseFloat(get(zoomGroup, 'settings.MAX_ZOOM_LEVEL', DEFAULT_MAX_ZOOM));
  });

  const facetImages = isAnomalyImages ? anomalyImages : softMetalImages;
  const enableInclusion = isAnomalyImages ? enableSkydioImageryReportInclusion : enableSoftMetalReportInclusion;
  const reportIncludedImages = isAnomalyImages ? reportIncludedSkydioImages : reportIncludedSoftMetalImages;

  const chunkedFacetImageIds = chunk(
    facetImages.map((image) => image.urn),
    MAX_IMAGES_BY_ROW,
  );
  const selectedImage = get(
    chunkedFacetImageIds,
    [selectedFacetImageIndex.x, selectedFacetImageIndex.y],
    '',
  );

  // Below three declarations are temporary, just for UI to work, need to update when APIs are integrated (HH-1225, HH-1226).
  // const [included, setIncluded] = useState([]);
  const isReportAttached = (urn) => reportIncludedImages.includes(urn);
  const reportInclusionPayload = () => ({ orderId, facetAliasName, data: { referenceIds: [selectedImage] } });

  const fetchImagesIncludedInReport = () => {
    if (isAnomalyImages) {
      fetchSkydioImagesIncludedInReport({ orderId, facetAliasName });
    } else {
      fetchSoftMetalImagesIncludedInReport(orderId);
    }
  };

  const saveImageReportInclusion = () => {
    if (isAnomalyImages) {
      saveSkydioImageReportInclusion(reportInclusionPayload());
    } else {
      saveSoftMetalImageReportInclusion(reportInclusionPayload());
    }
  };

  const deleteImageReportInclusion = () => {
    if (isAnomalyImages) {
      deleteSkydioImageReportInclusion(reportInclusionPayload());
    } else {
      deleteSoftMetalImageReportInclusion(reportInclusionPayload());
    }
  };

  const handleReportAttached = (isIncluded) => {
    if (enableInclusion && isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) && !orderCompleted) {
      if (isIncluded) {
        saveImageReportInclusion();
        if (!facetIncludeInReport) {
          saveFacetReportInclusion({
            orderId,
            facetId,
            data: {
              decision: facetDecision,
              facetAliasName,
              roofId,
              includeInReport: true,
            },
          });
        }
      } else {
        deleteImageReportInclusion();
      }
    }
  };

  const imageMetadata = get(
    facetImages.find((x) => x.urn === selectedImage),
    'metadata',
  );
  const bounds = {
    bl: { lon: -180, lat: -85 },
    ur: { lon: 180, lat: 85 },
    ul: { lon: -180, lat: 85 },
    br: { lon: 180, lat: -85 },
  };
  const maxZoom = get(imageMetadata, ['zoom_range', 'maximum_zoom_level'], MAPVIEWER_MAX_ZOOM);

  // eslint-disable-next-line consistent-return
  const isInViewport = (element) => {
    if (element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  };

  const scrollToGalleryThumbnail = (x, y) => {
    const currentFacetImageEl = document.getElementById(`metalSoftGalleryThumbnail-${x}-${y}`);
    if (!isInViewport(currentFacetImageEl) && currentFacetImageEl) {
      currentFacetImageEl.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const checkValidItem = (x, y) => get(chunkedFacetImageIds, `[${x}][${y}]`, false);

  const selectFacetImage = (x, y) => {
    setSelectedFacetImageIndex({ x, y });
    scrollToGalleryThumbnail(x, y);
  };

  const authToken = useSelector((state) => state.adjusterReducer.accessToken);

  useEffect(() => {
    if (enableInclusion && !isEmpty(orderId)) {
      fetchImagesIncludedInReport();
    }
  }, [isAnomalyImages]);

  useEffect(() => {
    if (selectedImage) {
      const imageUrl = `${ASSESS_IMAGES_API_ENDPOINT}/${selectedImage}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&`
      + `${sharedUtil.getAuthParam(authToken)}`;
      setAssets([
        {
          uuid: selectedImage,
          assetType: 'mosaic',
          tileUrls: [imageUrl],
        },
      ]);
      setView(defaultView);
      setLocalView(defaultView);
    }
    if (selectedImage && !imageMetadata) {
      fetchFacetImageMetadata({ imageURN: selectedImage, isAnomalyImages });
    }
  }, [selectedImage, authToken]);

  const setPreviousFacetImage = () => {
    const newYIndex = selectedFacetImageIndex.y - 1;
    if (checkValidItem(selectedFacetImageIndex.x, newYIndex)) {
      setSelectedFacetImageIndex({ x: selectedFacetImageIndex.x, y: newYIndex });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x, newYIndex);
    } else if (checkValidItem(selectedFacetImageIndex.x - 1, MAX_IMAGE_ROW_INDEX)) {
      setSelectedFacetImageIndex({
        x: selectedFacetImageIndex.x - 1,
        y: MAX_IMAGE_ROW_INDEX,
      });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x - 1, MAX_IMAGE_ROW_INDEX);
    }
  };

  const setNextFacetImage = () => {
    const newYIndex = selectedFacetImageIndex.y + 1;
    if (checkValidItem(selectedFacetImageIndex.x, newYIndex)) {
      setSelectedFacetImageIndex({ x: selectedFacetImageIndex.x, y: newYIndex });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x, newYIndex);
    } else if (checkValidItem(selectedFacetImageIndex.x + 1, MIN_IMAGE_ROW_INDEX)) {
      setSelectedFacetImageIndex({
        x: selectedFacetImageIndex.x + 1,
        y: MIN_IMAGE_ROW_INDEX,
      });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x + 1, MIN_IMAGE_ROW_INDEX);
    }
  };

  const setLeftOrRightFacetImage = (keyCode) => {
    const newYIndex = keyCode === KEY_CODE.LEFT
      ? selectedFacetImageIndex.y - 1
      : selectedFacetImageIndex.y + 1;
    if (checkValidItem(selectedFacetImageIndex.x, newYIndex)) {
      setSelectedFacetImageIndex({ x: selectedFacetImageIndex.x, y: newYIndex });
      scrollToGalleryThumbnail(selectedFacetImageIndex.x, newYIndex);
    }
  };

  const setUpOrDownFacetImage = (keyCode) => {
    const newXIndex = keyCode === KEY_CODE.UP
      ? selectedFacetImageIndex.x - 1
      : selectedFacetImageIndex.x + 1;
    if (checkValidItem(newXIndex, selectedFacetImageIndex.y)) {
      setSelectedFacetImageIndex({ x: newXIndex, y: selectedFacetImageIndex.y });
      scrollToGalleryThumbnail(newXIndex, selectedFacetImageIndex.y);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keyPress) {
      switch (keyPress.keyCode) {
        case KEY_CODE.LEFT:
          setLeftOrRightFacetImage(KEY_CODE.LEFT);
          break;
        case KEY_CODE.RIGHT:
          setLeftOrRightFacetImage(KEY_CODE.RIGHT);
          break;
        case KEY_CODE.UP:
          setUpOrDownFacetImage(KEY_CODE.UP);
          break;
        case KEY_CODE.DOWN:
          setUpOrDownFacetImage(KEY_CODE.DOWN);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [keyPress]);

  const handleKeyUp = (event) => {
    setKeyPress(event);
  };

  const addKeyUpHandler = () => {
    window.addEventListener('keyup', handleKeyUp);
  };

  const removeKeyUpHandler = () => {
    setSelectedFacetImageIndex({ x: MIN_IMAGE_ROW_INDEX, y: MIN_IMAGE_ROW_INDEX });
    window.removeEventListener('keyup', handleKeyUp);
  };

  const handleRotate = () => {
    setView({ ...localView, rotation: localView.rotation - 90 });
  };
  const handleZoomIn = () => {
    if (view.zoom < maxZoom) setView({ ...localView, zoom: localView.zoom + 1 });
  };
  const handleZoomOut = () => {
    if (view.zoom > 0) {
      setView({ ...localView, zoom: localView.zoom - 1 });
    }
  };
  const handleFitToWindow = () => {
    if (imageMetadata && mapViewerRef.current) {
      mapViewerRef.current.fitImageToWindow({ image: imageMetadata });
    }
  };
  const handleFullSize = () => {
    if (imageMetadata && mapViewerRef.current) {
      mapViewerRef.current.oneToOneFit({ image: imageMetadata });
    }
  };

  const updateLocalView = (updatedView) => {
    setLocalView({ ...updatedView });
  };
  const setDebounceLocalView = debounce(updateLocalView, 250);

  const onViewUpdated = (updatedView) => {
    setDebounceLocalView(updatedView);
    refreshTokenCompleted();
  };

  return (
    <EVDialog
      open={open}
      onClose={handleClose}
      onEntered={addKeyUpHandler}
      onExit={removeKeyUpHandler}
      PaperProps={{ className: styles.paperStyle }}
    >
      <EVBox display="flex" height="100%" p={3}>
        <EVBox position="relative" flex="1 1 auto" className={styles.gallerySelectedImageContainer}>
          <EVMapViewer
            ref={mapViewerRef}
            view={view}
            assets={assets}
            maxZoom={maxZoom}
            onViewUpdated={onViewUpdated}
            baseMap="blank"
            bound={bounds}
            initialConfig={{
              style: { backgroundColor: '#000' },
              mapBoxKey: MAPBOX_KEY,
            }}
          />
          <EVBox position="absolute" right={theme.spacing(2)} bottom={theme.spacing(2)}>
            <EVToolbarPanel
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: '4px' }}
              className={styles.toolbarPanel}
            >
              <EVIconButton tooltip={t('workflowpanelTooltip.fullSize')} onClick={handleFullSize}>
                <EVFullSize />
              </EVIconButton>
              <EVIconButton tooltip={t('workflowpanelTooltip.fitToWindow')} onClick={handleFitToWindow}>
                <EVCropFree />
              </EVIconButton>
              <EVIconButton tooltip={t('workflowpanelTooltip.rotate')} onClick={handleRotate}>
                <EVRotateRight />
              </EVIconButton>
            </EVToolbarPanel>
            <EVToolbarPanel
              displayType="vertical"
              iconStyles={{ height: 'auto', borderRadius: '4px' }}
              className={clsx(styles.toolbarPanel, styles.toolbarPanelSpacing)}
            >
              <EVIconButton tooltip={t('workflowpanelTooltip.zoomIn')} onClick={handleZoomIn}>
                <EVAdd />
              </EVIconButton>
              <EVIconButton tooltip={t('workflowpanelTooltip.zoomOut')} onClick={handleZoomOut}>
                <EVRemove />
              </EVIconButton>
            </EVToolbarPanel>
          </EVBox>
          <EVBox
            clone
            position="absolute"
            left={theme.spacing(2)}
            top="50%"
            className={styles.galleryArrowButton}
          >
            <EVIconButton onClick={setPreviousFacetImage}>
              <EVChevronLeft />
            </EVIconButton>
          </EVBox>
          <EVBox
            clone
            position="absolute"
            right={theme.spacing(2)}
            top="50%"
            className={styles.galleryArrowButton}
          >
            <EVIconButton onClick={setNextFacetImage}>
              <EVChevronRight />
            </EVIconButton>
          </EVBox>
        </EVBox>
        <EVBox display="flex" flex="0 0 300px" ml={3} overflow="hidden" flexDirection="column">
          <EVBox display="flex" justifyContent="space-between" mb={3}>
            <EVBox>
              <div className={styles.roofItemsDialogTitle}>
                {isAnomalyImages ? `${t('facetImageDialog.facet')} ${facetAliasName}` : t('workflowpanel.softMetals')}
              </div>
              {!isAnomalyImages
                ? (
                  <div className={styles.roofItemsDialogSubTitle}>
                    {`${t('facetImageDialog.facet')} ${facetAliasName}`}
                  </div>
                )
                : null}
            </EVBox>
            <EVIconButton onClick={handleClose}>
              <EVClear />
            </EVIconButton>
          </EVBox>
          <EVBox className={styles.imagesContainer}>
            {chunkedFacetImageIds.map((subarr, x) => subarr.map((urn, y) => {
              const imageIsSelected = urn === selectedImage;
              return (
                <EVBox
                  key={urn}
                  className={styles.galleryThumbnailListItem}
                  onClick={() => selectFacetImage(x, y)}
                >
                  <img
                    id={`metalSoftGalleryThumbnail-${x}-${y}`}
                    className={clsx(
                      styles.galleryImageThumbnail,
                      imageIsSelected && styles.galleryImageThumbnailActive,
                    )}
                    src={`${ASSESS_IMAGES_API_ENDPOINT}/${urn}/thumb?width=75&height=75&${sharedUtil.getAuthParam(authToken)}`}
                    alt="facetImagethumbnail"
                  />
                  {enableInclusion && isReportAttached(urn)
                    && (
                      <EVBox className={clsx(styles.thumbnailOverlay, imageIsSelected && styles.thumbnailOverlaySelected)}>
                        <EVPaperClipIcon className={clsx(styles.tagIconStyle, !isReportAttached(urn) && styles.iconOpacity)} />
                      </EVBox>
                    )}
                </EVBox>
              );
            }))}
          </EVBox>
          {enableInclusion
            && (
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_REPORTS}>
                <ReportSwitch
                  included={isReportAttached(selectedImage)}
                  disabled={!isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) || orderCompleted}
                  setIncluded={handleReportAttached}
                  isSoftMetal
                />
              </EntitledComponent>
            )}
        </EVBox>
      </EVBox>
    </EVDialog>
  );
};

FacetImagesDialog.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  facetAliasName: string.isRequired,
  isAnomalyImages: bool.isRequired,
  facetId: string.isRequired,
  facetDecision: string.isRequired,
  facetIncludeInReport: bool.isRequired,
};

export default FacetImagesDialog;
