import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import clsx from 'clsx';
import {
  EVBox,
  EVPaper,
  EVTypography,
} from '@eagleview/ev-comp-library';
import { MAPBOX_KEY } from 'constants.js';
import isEmpty from 'lodash/isEmpty';
import EVMapViewer from '@eagleview/mapviewer-react';
import ToastMessage from 'components/toast-message';
import { useTranslation } from 'react-i18next';
import * as action from './DroneToMeasure.actions';
import useStyles from './DroneToMeasure.styles';

const DroneToMeasure = () => {
  // utils
  const params = useParams();
  const { t } = useTranslation();
  const styles = useStyles();

  // local state
  const [interactionObj, setInteractionObj] = useState({
    enable: false,
    type: 'tracking',
    mode: { click: true, hover: false },
    assetId: '',
    annotation: {},
  });

  // redux actions
  const dispatch = useDispatch();
  const handleToastClose = () => dispatch(action.closeErrorToast());
  const fetchOrderDetails = (payload) => dispatch(action.fetchOrderDetailsAction(payload));
  const setAnnotationToEdit = (payload) => dispatch(action.setAnnotationToEditAction(payload));
  const updateVerticesAndPolygons = (payload) => dispatch(action.updateVerticesAndPolygonsAction(payload));

  // selectors
  const toastMessage = useSelector((state) => get(state, 'droneToMeasureReducer.toastMessage'));
  const errorCode = useSelector((state) => get(state, 'droneToMeasureReducer.errorCode', ''));
  const showToast = useSelector((state) => get(state, 'droneToMeasureReducer.showToast'));
  const toastSeverity = useSelector((state) => get(state, 'droneToMeasureReducer.toastSeverity'));
  const view = useSelector((state) => get(state, 'droneToMeasureReducer.view'));
  const vertices = useSelector((state) => get(state, 'droneToMeasureReducer.vertices'));
  const polygon = useSelector((state) => get(state, 'droneToMeasureReducer.polygon'));
  const orthoImage = useSelector((state) => get(state, 'droneToMeasureReducer.orthoImage'));

  const setMapTracking = (setVal) => {
    setInteractionObj({
      ...interactionObj,
      enable: setVal,
      type: 'tracking',
      mode: { click: true, hover: false },
      assetId: '',
      annotation: {},
    });
  };

  useEffect(() => {
    fetchOrderDetails({ orderId: params.orderId });
  }, []);

  const handleMapClick = (e) => {
    if (e.state === 'tracked') {
      const { annotation: annotationObject } = e;
      const eventType = get(e, 'event.type', false);
      if (eventType !== 'click' || annotationObject.assetId !== 'vertices') return;
      if (Object.keys(annotationObject).length > 0) {
        const annotationToEdit = annotationObject;
        setInteractionObj({
          ...interactionObj,
          enable: true,
          type: 'editing',
          mode: { edit: true },
          assetId: annotationToEdit.assetId,
          annotation: annotationToEdit.annotation,
        });
        setAnnotationToEdit(annotationToEdit);
      }
    }
    if (e.state === 'completed') {
      const { annotation } = e;
      setMapTracking(true);
      updateVerticesAndPolygons(annotation);
    }
  };

  const handleMapReady = () => {
    setMapTracking(true);
  };

  return (
    <>
      <EVBox height="100%">
        <EVBox clone p={2} height="100%">
          <EVPaper className={styles.paper}>
            <EVTypography className={styles.title} variant="h1">{t('droneToMeasure.title')}</EVTypography>
            <EVBox display="flex" height="80%">
              <EVBox
                flex="1 1 50%"
                maxWidth="85%"
                height="100%"
                position="relative"
                bgcolor="black"
                className={clsx(styles.crosshair)}
                mr={1}
              >
                <EVMapViewer
                  view={view}
                  assets={
                    isEmpty(orthoImage)
                      ? [
                        ...(polygon.length > 0 && vertices.length > 0) ? [{ ...polygon[0], beforeAsset: vertices[0] }] : [],
                        ...vertices,
                      ]
                      : [
                        { ...orthoImage[0], beforeAsset: polygon[0] },
                        ...(polygon.length > 0 && vertices.length > 0) ? [{ ...polygon[0], beforeAsset: vertices[0] }] : [],
                        ...vertices,
                      ]
                  }
                  baseMap="blank"
                  interaction={{
                    ...interactionObj,
                    handler: handleMapClick,
                  }}
                  initialConfig={{
                    style: { backgroundColor: '#000' },
                    mapBoxKey: MAPBOX_KEY,
                  }}
                  onMapReady={handleMapReady}
                />
              </EVBox>
            </EVBox>
          </EVPaper>
        </EVBox>
      </EVBox>
      <ToastMessage
        alertMsg={`${t(toastMessage)} ${errorCode ? `${t('home.referCode')} ${errorCode}` : ''}`}
        severity={toastSeverity}
        open={!!showToast}
        onClose={handleToastClose}
        autoHideDuration={10000}
      />
    </>
  );
};

export default DroneToMeasure;
