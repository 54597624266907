import React, { useState, useEffect } from 'react';
import {
  EVBox,
  EVDialog,
  EVTypography,
  EVButton,
  EVInputLabel,
  EVRadio,
  EVCircularProgress,
} from '@eagleview/ev-comp-library';
import { useSelector, useDispatch } from 'react-redux';
import { bool, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  automatedSummaryBuilderAction,
  updateUserSelectionForAnomalyDetectionAction,
  setRunAutomatedSummaryBuilderWithOnOptionAction,
} from 'layout/adjuster/Adjuster.actions';
import { AUTOMATED_SUMMARY_BUILDER } from 'layout/adjuster/Adjuster.constants';
import { get } from 'lodash';
import useStyles from './AutomatedSummaryBuilder.styles';

const AutomatedSummaryBuilderModal = ({
  open, handleClose, orderId, isAutomatedSummaryMenuClicked,
}) => {
  const styles = useStyles();

  // actions
  const dispatch = useDispatch();
  const automatedSummaryBuilder = (payload) => dispatch(automatedSummaryBuilderAction(payload));
  const updateUserSelectionForAnomalyDetection = (payload) => dispatch(updateUserSelectionForAnomalyDetectionAction(payload));
  const setRunAutomatedSummaryBuilderWithOnOption = (payload) => dispatch(setRunAutomatedSummaryBuilderWithOnOptionAction(payload));

  // local state
  const [selectedValue, setSelectedValue] = useState('yes');

  // selectors
  const apiSuccess = useSelector((state) => get(state, 'adjusterReducer.apiSuccess', {}));
  const loading = useSelector((state) => get(state, 'adjusterReducer.loading', {}));
  const isAutomatedBuildSuccess = apiSuccess[AUTOMATED_SUMMARY_BUILDER];
  const isAutomatedBuildAPIInProgress = loading[AUTOMATED_SUMMARY_BUILDER];

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      event.preventDefault();
    }
  };

  const updateSelectionFlag = (userSelection) => {
    if (!(isAutomatedSummaryMenuClicked && selectedValue === 'no')) {
      updateUserSelectionForAnomalyDetection({ orderId, userSelection, selectedValue });
    }
    handleClose();
  };

  const handleAutomatedSummaryBuilder = () => {
    if (selectedValue === 'yes') {
      automatedSummaryBuilder({ orderId });
    } else {
      updateSelectionFlag(false);
    }
  };

  const onRadioInputChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    setRunAutomatedSummaryBuilderWithOnOption(false);
  }, []);

  useEffect(() => {
    if (isAutomatedBuildSuccess) {
      updateSelectionFlag(true);
    }
  }, [isAutomatedBuildSuccess]);

  const { t } = useTranslation();
  return (
    <EVDialog data-testid="automated-summary-dialog" open={open} onClose={handleDialogClose}>
      <EVBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="520px"
        height="240px"
        p={2}
      >
        <EVBox>
          <EVBox display="flex" justifyContent="space-between" alignItems="center">
            <EVBox>
              <EVTypography variant="h3">{t('automatedSummaryBuilder.title')}</EVTypography>
            </EVBox>
          </EVBox>
          <EVBox pt={3.5}>
            <EVTypography style={{ color: 'gray' }} variant="body1">{t('automatedSummaryBuilder.confirmation')}</EVTypography>
          </EVBox>
        </EVBox>
        <EVBox display="flex" justifyContent="center">
          <EVInputLabel>
            <EVRadio checked={selectedValue === 'no'} value="no" onChange={onRadioInputChange} data-testid="no-button" color="primary" />
            {t('no')}
          </EVInputLabel>
          <EVInputLabel style={{ marginLeft: '20px' }}>
            <EVRadio checked={selectedValue === 'yes'} value="yes" onChange={onRadioInputChange} data-testid="yes-button" color="primary" />
            {t('yes')}
          </EVInputLabel>
        </EVBox>
        <EVBox display="flex" justifyContent="center">
          <EVButton
            className={styles.doneButton}
            onClick={handleAutomatedSummaryBuilder}
            data-testid="done-button"
            variant="contained"
            color="primary"
            disabled={isAutomatedBuildAPIInProgress}
          >
            {isAutomatedBuildAPIInProgress ? t('running') : t('done')}
            {isAutomatedBuildAPIInProgress && <EVCircularProgress size={20} />}
          </EVButton>
        </EVBox>
      </EVBox>
    </EVDialog>
  );
};

AutomatedSummaryBuilderModal.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  orderId: number.isRequired,
  isAutomatedSummaryMenuClicked: bool.isRequired,
};

export default AutomatedSummaryBuilderModal;
