import { makeStyles } from '@eagleview/ev-comp-library';

export default makeStyles(() => ({
  doneButton: {
    textTransform: 'none',
  },
  circularProgressBar: {
    height: '12px',
  },
}));
