import { makeStyles } from '@eagleview/ev-comp-library';

const useStyles = makeStyles((theme) => ({
  editButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  datePickerWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
  },
  datePicker: {
    position: 'absolute',
    left: '340px',
    top: '0',
    zIndex: 1300,
    transform: 'none !important',
  },
  dialogBox: {
    width: theme.spacing(48),
    height: theme.spacing(14),
    padding: 0,
  },
  inputBox: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
