import React from 'react';
import {
  EVBox,
  EVDialog,
  EVTypography,
  EVButton,
} from '@eagleview/ev-comp-library';
import { bool, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { resetOrderToReadyForReviewAction } from 'layout/adjuster/Adjuster.actions';
import useStyles from './ResetDecisions.styles';

const resetDecisions = ({ open, handleClose, orderId }) => {
  // hooks
  const styles = useStyles();
  // actions
  const dispatch = useDispatch();
  const resetOrderToReadyForReview = (payload) => dispatch(resetOrderToReadyForReviewAction(payload));

  const handleResetDecision = () => {
    resetOrderToReadyForReview({ orderId, retainData: false });
    handleClose();
  };

  const { t } = useTranslation();
  return (
    <EVDialog data-testid="reset-decision-dialog" open={open} onClose={handleClose}>
      <EVBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="520px"
        height="180px"
        p={2}
      >
        <EVBox>
          <EVBox display="flex" justifyContent="space-between" alignItems="center">
            <EVBox>
              <EVTypography variant="h3">{t('adjusterMenu.resetDecisions')}</EVTypography>
            </EVBox>
          </EVBox>
          <EVBox pt={2} pb={2}>
            <EVTypography style={{ color: 'gray' }} variant="body1">{t('adjusterMenu.resetDecisionsConfirmation')}</EVTypography>
          </EVBox>
        </EVBox>
        <EVBox display="flex" justifyContent="flex-end">
          <EVBox clone mr={1}>
            <EVButton
              className={styles.button}
              data-testid="cancelBtn"
              onClick={() => {
                handleClose();
              }}
              color="primary"
            >
              {t('cancel')}
            </EVButton>
          </EVBox>
          <EVButton
            className={styles.button}
            onClick={handleResetDecision}
            data-testid="submit-button"
            variant="contained"
            color="primary"
          >
            {t('adjusterMenu.resetButton')}
          </EVButton>
        </EVBox>
      </EVBox>
    </EVDialog>
  );
};

resetDecisions.propTypes = {
  open: bool.isRequired,
  handleClose: func.isRequired,
  orderId: number.isRequired,
};

export default resetDecisions;
