import axios from 'axios';
import * as util from 'utils/utils';
import {
  ASSESS_SERVICE_API_ENDPOINT,
  IW_API_ENDPOINT,
  ASSESS_DECISION_API_ENDPOINT,
  ASSESS_APPLICATION_SETTINGS_ENDPOINT,
  ASSESS_SERVICE_V2_API_ENDPOINT,
  OPS_PRIME_API_ENDPOINT,
  ASSESS_ORDER_MANAGER_API_ENDPOINT,
  SHARE_LINK_ENDPOINT,
  ASSESS_SERVICE_INTERNAL_API_ENDPOINT,
} from 'constants.js';

export async function loadAdjusterDetails({ orderId }) {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function toggleAnomalyIncludeInReportApi({ orderID, anomalyID, data }) {
  try {
    const { data: response } = await axios.patch(`${ASSESS_DECISION_API_ENDPOINT}/orders/${orderID}/anomalies/${anomalyID}`, data);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function getQcPanelDetails({ orderId, structureId }) {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/${orderId}${structureId ? `/structures/${structureId}` : ''}`);
    return (data);
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const postQcPanelClaimApprove = async ({
  orderId, ID, action, userID, isQcD2m,
}) => {
  const data = {
    ID, action, isQcD2m, userID,
  };
  try {
    const response = await axios.put(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/${orderId}/execute`, data);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postQcPanelClaimReject = async ({
  orderId, ID, action, reason, note, userID, isQcD2m,
}) => {
  const data = {
    ID, action, reason, note, userID, isQcD2m,
  };
  try {
    const response = await axios.put(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/${orderId}/execute`, data);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postQcPanelClaimRetiepoint = async ({
  orderId, ID, action, reason, note, userID, isQcD2m,
}) => {
  const data = {
    ID, action, reason, note, userID, isQcD2m,
  };
  try {
    const response = await axios.put(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/${orderId}/execute`, data);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const checkoutByReportIdForQc = async (orderId) => {
  try {
    const { data } = await axios.get(`${OPS_PRIME_API_ENDPOINT}/CheckOutByReportId?ReportId=${orderId}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const checkInSimpleForQc = async (taskStateId) => {
  try {
    const { data: response } = await axios.get(
      `${OPS_PRIME_API_ENDPOINT}/CheckInSimple?TaskStateId=${taskStateId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const rejectSimpleNotesForQc = async (taskStateId, rejectReasonId, body, assignPreferredUser) => {
  try {
    const { data: response } = await axios.post(
      `${OPS_PRIME_API_ENDPOINT}/RejectSimpleNotes?TaskStateId=${taskStateId}&RejectReasonId=${rejectReasonId}&AssignPreferredUser=${assignPreferredUser}`,
      body,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postQcPanelClaimReschedule = async ({
  orderId, ID, action, reason, note, userID, isQcD2m,
}) => {
  const data = {
    ID, action, reason, note, userID, isQcD2m,
  };
  try {
    const response = await axios.put(`${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/${orderId}/execute`, data);
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const rejectQcStructureApi = async ({ orderId, structureId }) => {
  try {
    const { data } = await axios.patch(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/meta/type/roof`,
      { state: 'MANUAL_QC' },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const undoQcStructureRejectionApi = async ({ orderId, structureId }) => {
  try {
    const { data } = await axios.patch(
      `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/meta/type/roof`,
      { state: 'MANUAL_QC', Undo: true },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const downloadSkydioMosaicImage = async ({ orderId, structureId }) => {
  try {
    const fetchSkydioMosaicImageUrl = structureId ? `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/qc/orders/${orderId}/structures/${structureId}/skydio-mosaic`
      : `${ASSESS_ORDER_MANAGER_API_ENDPOINT}/qc/orders/${orderId}/skydio-mosaic`;
    const { data } = await axios.get(
      fetchSkydioMosaicImageUrl, { responseType: 'blob' },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function loadRoofFacetsDetails({ orderId, structureId }) {
  let data;
  if (structureId) {
    ({ data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/assets`));
  } else {
    ({ data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/assets`));
  }
  return data;
}

export async function postReport(orderId, tzoffset) {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_V2_API_ENDPOINT}/orders/${orderId}/report?tzoffset=${tzoffset}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function getReportAttempts(orderId) {
  try {
    const { data } = await axios.get(`${SHARE_LINK_ENDPOINT}/gateway/sharelink/generate_report_attempts/${orderId}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}
export async function pollReportGeneration(orderId, reportId) {
  try {
    const { data, status } = await axios.get(`${ASSESS_SERVICE_V2_API_ENDPOINT}/orders/${orderId}/reports/${reportId}/status`);
    return { data, status };
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchFacetBaseImageApi({ orderId, structureId }) {
  let data;
  if (structureId) {
    ({ data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images?imageType=baseImage`,
    ));
  } else {
    ({ data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?imageType=baseImage`,
    ));
  }
  return data;
}

export async function fetchRiskShot({ orderId, structureId }) {
  let data;
  if (structureId) {
    ({ data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images?imageType=riskShot`,
    ));
  } else {
    ({ data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?imageType=riskShot`,
    ));
  }
  return data;
}

export async function fetchIwMetadata(urn) {
  try {
    const { data } = await axios.get(`${IW_API_ENDPOINT}/images/${urn}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchFacetScanImages({
  facetAliasName, orderId, structureId, lat, long,
}) {
  try {
    let data;
    let status;
    if (structureId) {
      ({ data, status } = await axios.get(
        // eslint-disable-next-line
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images?facetAliasName=${facetAliasName}&imageType=FACET_SCAN&lat=${lat}&long=${long}`,
      ));
    } else {
      ({ data, status } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?facetAliasName=${facetAliasName}&imageType=FACET_SCAN&lat=${lat}&long=${long}`,
      ));
    }
    return { data, status };
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchFacetMosaicImages({ orderId, structureId }) {
  try {
    let data;
    let status;
    if (structureId) {
      ({ data, status } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images?imageType=FACET_MOSAIC`,
      ));
    } else {
      ({ data, status } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?imageType=FACET_MOSAIC`,
      ));
    }
    return { data, status };
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchSoftMetalImages({ facetAliasName, orderId, structureId }) {
  try {
    let data;
    let status;
    if (structureId) {
      ({ data, status } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images?facetAliasName=${facetAliasName}&imageType=SOFT_METAL`,
      ));
    } else {
      ({ data, status } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?facetAliasName=${facetAliasName}&imageType=SOFT_METAL`,
      ));
    }
    return { data, status };
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function completeReview(orderId) {
  try {
    const { data } = await axios.put(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/completeReview`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveAnomalyDecision(orderId, anomalyData) {
  try {
    const { data } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/anomalies/${anomalyData.anomalyId}`,
      anomalyData,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function oldAddUserAnomaly(orderId, anomalyData) {
  try {
    const { data } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/anomalies`,
      anomalyData,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function addUserAnomaly(orderId, anomalyData, structureID, long, lat) {
  try {
    const { data } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/structures/${structureID}/raw/anomalies?lat=${lat}&long=${long}`,
      anomalyData,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function deleteUserAnomaly(orderId, anomalyId) {
  try {
    const { data } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/anomalies/${anomalyId}`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function postRoofDecision({ orderId, roofId, data }) {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/roofs/${roofId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function loadAdjusterDetailsGallery({ orderId, structureId }) {
  try {
    let data;
    if (structureId) {
      ({ data } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureId}/images?imageType=nonDroneImage`,
      ));
    } else {
      ({ data } = await axios.get(
        `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images?imageType=nonDroneImage`,
      ));
    }
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveRotationforGalleryImages({
  orderId, image, data,
}) {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/images/${image}/customMeta`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function postFacetDecision({ orderId, facetId, data }) {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/facets/${facetId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveFacetReportInclusion({ orderId, facetId, data }) {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/facets/${facetId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveImageNoteReportInclusion({
  orderId, imageUrn, noteId, data,
}) {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/images/${imageUrn}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveFacetNoteReportInclusion({
  orderId, facetId, noteId, data,
}) {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facetId}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchImagesIncludedInReport(orderId) {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/images`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveImageReportInclusion({ orderId, data }) {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/images`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function deleteImageReportInclusion({ orderId, data }) {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/images`,
      {
        data,
      },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchProductCost({ orderId, emailId }) {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/report/productCost/${emailId}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const generateRoofReport = async ({ orderId }) => {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/generate/evreport`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const regenerateReport = async ({ orderId }) => {
  try {
    const data = await axios.post(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/regenerateReport`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function showReport(reportUrl) {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}${reportUrl}`, { responseType: 'blob' });
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function showReportUsingReportId({ reportID, orderID, reportType }) {
  try {
    const { data } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderID}/sources/${reportType}/reports/${reportID}/evreport`, { responseType: 'blob' },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const fetchImagesNotes = async (orderId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/images/notes`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchFacetNotes = async (orderId) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/notes`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchFacetTestSquareNotes = async ({ orderId, testSquareId }) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/testsquares/${testSquareId}/notes`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchImagesTags = async ({ orderId }) => {
  try {
    const { data } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/tags`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postImageTag = async ({ data, image, orderId }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/${image}/tags`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteImageTag = async ({ data, image, orderId }) => {
  try {
    const {
      data: response,
    } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/${image}/tags`,
      { data },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postImagesNote = async ({ data, image, orderId }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/images/${image}/notes`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateImagesNote = async ({
  data, image, orderId, noteId,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/images/${image}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteImagesNote = async ({ image, orderId, noteId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/images/${image}/notes/${noteId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postFacetNote = async ({ data, facet, orderId }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facet}/notes`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateFacetNote = async ({
  data, facet, orderId, noteId,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facet}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteFacetNote = async ({ facet, orderId, noteId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facet}/notes/${noteId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const postFacetTestSquareNote = async ({ data, testSquareId, orderId }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/testsquares/${testSquareId}/notes`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateFacetTestSquareNote = async ({
  data, testSquareId, orderId, noteId,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/testsquares/${testSquareId}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteFacetTestSquareNote = async ({ testSquareId, orderId, noteId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/testsquares/${testSquareId}/notes/${noteId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function saveFacetTestSquareNoteReportInclusion({
  orderId, testSquareId, noteId, data,
}) {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/testsquares/${testSquareId}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const fetchRoofNoteApi = async ({ roofId, orderId }) => {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/roofs/${roofId}/notes`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const saveRoofNoteApi = async ({ roofId, orderId, data }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/roofs/${roofId}/notes`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateRoofNoteApi = async ({
  roofId, orderId, data, noteId,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/roofs/${roofId}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteRoofNoteApi = async ({ roofId, orderId, noteId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/roofs/${roofId}/notes/${noteId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function fetchGalleryImageMetadata(urn) {
  try {
    const { data } = await axios.get(`${IW_API_ENDPOINT}/images/${urn}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const saveGalleryAnnotationApi = async ({ orderId, imageId, data }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/${imageId}/annotations`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateGalleryAnnotationInclInReportApi = async ({
  orderId, imageId, annotationId, data,
}) => {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/images/${imageId}/annotations/${annotationId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateGalleryAnnotationApi = async ({
  orderId, imageId, annotationId, data,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/${imageId}/annotations/${annotationId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const displayGalleryAnnotationApi = async ({ orderId, imageId }) => {
  try {
    const { data } = await axios.get(`${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/${imageId}/annotations`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteGalleryAnnotationApi = async ({ orderId, imageId, annotationId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/images/${imageId}/annotations/${annotationId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const testSquareStatusUpdateApi = async ({ orderId, facetId, data }) => {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/facets/${facetId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const exportAllImagesApi = async (orderId) => {
  try {
    const { data } = await axios.post(`${ASSESS_SERVICE_V2_API_ENDPOINT}/orders/${orderId}/images/export?onlyreport=false`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const exportReportIncludedImagesApi = async (orderId) => {
  try {
    const { data } = await axios.post(`${ASSESS_SERVICE_V2_API_ENDPOINT}/orders/${orderId}/images/export?onlyreport=true`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function pollExportImagesApi(orderId, exportId) {
  try {
    const { data, status } = await axios.get(`${ASSESS_SERVICE_V2_API_ENDPOINT}/orders/${orderId}/images/export/${exportId}/status`);
    return { data, status };
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const downloadExportedImagesApi = async (orderId, exportId) => {
  try {
    const response = await axios.get(`${ASSESS_SERVICE_V2_API_ENDPOINT}/orders/${orderId}/images/export/${exportId}`, { responseType: 'arraybuffer' });
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchAssessAppSettingsApi = async () => {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_APPLICATION_SETTINGS_ENDPOINT}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchReportVersionsApi = async (orderId) => {
  try {
    const { data } = await axios.get(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/report/versions`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateTestSquareLocationApi = async ({
  orderId, facetId, testSquareId, data,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/facets/${facetId}/testsquares/${testSquareId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const fetchFeaturePreferenceApi = async () => {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/feature-preferences`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const saveConfidenceFactorApi = async (orderId, confidenceFactor) => {
  try {
    const response = await axios.patch(`${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/confidenceScore`, { ConfidenceScoreSelection: confidenceFactor });
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const saveAnnotationForFacet = async (orderId, facetId, data) => {
  try {
    const response = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facetId}/annotations`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateUserAnomalyLocationApi = async (data, orderId, anomalyId) => {
  try {
    const response = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/anomalies/${anomalyId}`,
      data,
    );
    return response.data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const displayFacetAnnotationApi = async ({ orderId, facetId }) => {
  try {
    const { data } = await axios.get(`${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facetId}/annotations`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateFacetAnnotationApi = async ({
  orderId, facetId, annotationId, data,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facetId}/annotations/${annotationId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateFacetAnnotationInclInReportApi = async ({
  orderId, facetId, annotationId, data,
}) => {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facetId}/annotations/${annotationId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteFecetAnnotationApi = async ({ orderId, facetId, annotationId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/facets/${facetId}/annotations/${annotationId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const getAnomalyNotesApi = async ({ orderId, anomalyId }) => {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/anomaly/${anomalyId}/notes`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const addAnomalyNoteApi = async ({ orderId, anomalyId, data }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/anomaly/${anomalyId}/notes`,
      {
        ...data,
        includeInReport: true,
      },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateAnomalyNoteApi = async ({
  orderId, anomalyId, noteId, data,
}) => {
  try {
    const { data: response } = await axios.put(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/anomaly/${anomalyId}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const deleteAnomalyNoteApi = async ({ orderId, anomalyId, noteId }) => {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/anomaly/${anomalyId}/notes/${noteId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const includeAnomalyNoteApi = async ({
  orderId, anomalyId, noteId, data,
}) => {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/sources/anomaly/${anomalyId}/notes/${noteId}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function getAccessoryMasterList(categoryType) {
  try {
    const { data } = await axios.get(`${ASSESS_DECISION_API_ENDPOINT}/category/${categoryType}/accessoryMasterList`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function getAccessorySummary({ orderId, selectedStructureId }) {
  try {
    const { data } = await axios.get(`${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/accessorySummary?structureId=${selectedStructureId}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function getMultiStructureDecisions({ orderId }) {
  try {
    const { data } = await axios.get(`${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/decisions`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}
export const updateStructureVisitStatusApi = async ({
  orderID, structureID, data,
}) => {
  try {
    const { data: response } = await axios.patch(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderID}/structures/${structureID}/meta`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
export const postQcNote = async ({ data, orderId }) => {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_SERVICE_API_ENDPOINT}/qc/orders/${orderId}/sources/qcnotes`,
      { data },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export async function fetchSoftMetalImagesIncludedInReport(orderId) {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/softMetal`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveSoftMetalImageReportInclusion({ orderId, data }) {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/softMetal`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function deleteSoftMetalImageReportInclusion({ orderId, data }) {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/softMetal`,
      {
        data,
      },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchSkydioImagesIncludedInReport({ orderId, facetAliasName }) {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/facetScan?facetAliasName=${facetAliasName}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function saveSkydioImageReportInclusion({ orderId, facetAliasName, data }) {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/facetScan?facetAliasName=${facetAliasName}`,
      data,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function deleteSkydioImageReportInclusion({ orderId, facetAliasName, data }) {
  try {
    const { data: response } = await axios.delete(
      `${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/reports/facetScan?facetAliasName=${facetAliasName}`,
      {
        data,
      },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchFacetImageAnomalies({ orderId, structureID, imageURN }) {
  try {
    const { data: response } = await axios.get(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/structures/${structureID}/imageurn/${imageURN}/anomalylist`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function updateDateOfLoss({ orderId, dateOfLoss }) {
  try {
    const data = await axios.put(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/dateOfLoss/${dateOfLoss}`,
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function updateClaimNumber({ orderId, ClaimNumber }) {
  try {
    const data = await axios.post(
      `${ASSESS_SERVICE_API_ENDPOINT}/orders/${orderId}/updateReportAndRegenerate`,
      {
        ClaimNumber,
      },
    );
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function fetchSharedUsersList({ orderId }) {
  try {
    const { data: response } = await axios.get(
      `${SHARE_LINK_ENDPOINT}/sharelink/userlist/${orderId}`,
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function createShareLink({
  evUserID, sharedUserID, orderID, notes,
}) {
  try {
    const { data: response } = await axios.post(
      `${SHARE_LINK_ENDPOINT}/create_share_links`,
      {
        evUserID,
        sharedUserID,
        orderID,
        notes,
      },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export async function publishDomainEvent({ eventName, eventData }) {
  try {
    const { data: response } = await axios.post(
      `${ASSESS_SERVICE_INTERNAL_API_ENDPOINT}/publishdomainevent`,
      {
        event_name: eventName,
        event_data: eventData,
      },
    );
    return response;
  } catch (error) {
    return util.handleHttpError(error);
  }
}

export const resetOrderToReadyForReview = async ({ orderId, retainData }) => {
  try {
    const { data } = await axios.put(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/state/readytoreview?retainData=${retainData}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const automatedSummaryBuilder = async ({ orderId }) => {
  try {
    const { data } = await axios.post(`${ASSESS_DECISION_API_ENDPOINT}/orders/${orderId}/automatedanomalydetection`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};

export const updateUserSelectionForAnomalyDetection = async ({ orderId, userSelection }) => {
  try {
    const { data } = await axios.get(`${ASSESS_ORDER_MANAGER_API_ENDPOINT}/orders/${orderId}/updateUserSelectionForAnomalyDetection/${userSelection}`);
    return data;
  } catch (error) {
    return util.handleHttpError(error);
  }
};
