import React, {
  forwardRef, useEffect, useState, useRef, useReducer,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as action from 'layout/adjuster/Adjuster.actions';
import { ASSESS_IMAGES_API_ENDPOINT, MAPBOX_KEY } from 'constants.js';
import EVMapViewer from '@eagleview/mapviewer-react';
import { EVBox } from '@eagleview/ev-comp-library';
import { v4 as uuidv4 } from 'uuid';
import {
  get, isEqual, cloneDeep, isEmpty, isNull,
} from 'lodash';
import { func } from 'prop-types';
import debounce from 'lodash/debounce';
import {
  debugMode, logger, isSharedUrl, getAuthParam,
} from 'utils/utils';
import { getMapviewerAsset } from 'layout/adjuster/Adjuster.utils';
import * as authUtils from 'utils/auth.utils';
import {
  FEATURE_ENTITLEMENTS, GROUND_ACCESSORY, ANNOTATION_DESC, ASSESS_PHOTO_VIEWER,
  ASSESS_DIGITAL_DELIVERY,
} from 'layout/entitleUser/EntitleUser.constants';
import { push } from 'connected-react-router';
import EntitledComponent from 'components/EntitledComponent';
import ErrorBoundary from 'components/ErrorBoundary';
import { isEntitled } from 'utils/auth.utils';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import notesIcon from 'assets/notesIcon.svg';
import PhotosUITabs, { PhotosUITab } from 'layout/photos/PhotosUITabs';
import ShareReportPanel from 'layout/adjuster/share-report-panel/ShareReportPanel';
import AutomatedSummaryBuilderModal from 'components/AutomatedSummaryBuilder';
import useStyles from './ImagePreview.styles';
import {
  DEFAULT_MAX_ZOOM, FETCH_ADJUSTER_DETAILS_GALLERY, INPUTS, TABS,
} from '../Adjuster.constants';
import ImagePreviewerToolbar from './ImagePreviewerToolbar';
import ReportGenerator from '../reports/ReportGenerator';
import ExportImageryPanel from '../export-imagery/ExportImageryPanel';
import AccessoriesSummaryPanel from '../accessories-summary-panel';
import AccessoryDropdown from '../select-accessory-dropdown/AccessoryDropdown';
import Utc from '../utc/utc';
import {
  getBoxAnnotationDescIconCoordinates, getIntersection, getLeftMostCoordinate, getRightMostCoordinate, transformOrientation,
} from '../Adjuster.utils';
import AnnotationDescPanel, {
  annotationDescPanelReducer, annotationDescPanelConstants, isCompleteVisibleH, isCompleteVisibleV,
} from '../annotation-description';

const ImagePreview = forwardRef(({ showMapviewerError }, mapViewerRef) => {
  // bounds for handheld imagery
  const bounds = {
    bl: { lon: -180, lat: -85 },
    ur: { lon: 180, lat: 85 },
    ul: { lon: -180, lat: 85 },
    br: { lon: 180, lat: -85 },
  };

  const { t } = useTranslation();

  // selectors
  const selectedImage = useSelector((state) => state.adjusterReducer.selectedImage);
  const currentTabGalleryImages = useSelector((state) => state.adjusterReducer.currentTabGalleryImages);
  const filteredGalleryImages = useSelector((state) => state.adjusterReducer.filteredGalleryImages);
  const orderId = useSelector((state) => state.adjusterReducer.orderId);
  const gallaryAnnotations = useSelector((state) => state.adjusterReducer.gallaryAnnotations);
  const deleteStatus = useSelector((state) => state.adjusterReducer.deleteStatus);
  const entitlements = useSelector((state) => state.entitleUserReducer.entitlements);
  const orderCompleted = useSelector((state) => state.adjusterReducer.orderCompleted);
  const reportIncludedImages = useSelector((state) => state.adjusterReducer.reportIncludedImages);
  const accessories = useSelector((state) => state.adjusterReducer.accessoriesMasterList);
  const accessoriesSummary = useSelector((state) => state.adjusterReducer.accessoriesSummary);
  const enableGroundAccessory = useSelector((state) => get(state.entitleUserReducer.featureFlags, GROUND_ACCESSORY, false));
  const enableAnnotationDescription = useSelector((state) => get(state.entitleUserReducer.featureFlags, ANNOTATION_DESC, false));
  const isAssessPhotoViewerEnabled = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_PHOTO_VIEWER, false));
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const appliedFilters = useSelector((state) => state.adjusterReducer.appliedGalleryImageFilters);
  const galleryImages = useSelector((state) => state.adjusterReducer.galleryImages);
  const currentTab = useSelector((state) => state.adjusterReducer.currentTab);
  const isAssessLite = useSelector((state) => state.adjusterReducer.isAssessLite);
  const createdAnnotationToEdit = useSelector((state) => state.adjusterReducer.createdAnnotationToEdit);
  const {
    [FETCH_ADJUSTER_DETAILS_GALLERY]: fetchingAdjusterDetailsGallery,
  } = useSelector(
    (state) => state.adjusterReducer.loading,
  );
  const isDigitalDeliveryEnabled = useSelector((state) => get(
    state.entitleUserReducer.featureFlags,
    ASSESS_DIGITAL_DELIVERY,
    false,
  ));

  // actions
  const dispatch = useDispatch();
  const setSelectedImage = (payload) => dispatch(action.setSelectedImageAction(payload));
  const fetchImageMetadata = (imageUrn) => dispatch(action.fetchGalleryImageMetadataAction(imageUrn));
  const displayGalleryAnnotation = (payload) => dispatch(action.displayGalleryAnnotationAction(payload));
  const saveGalleryAnnotation = (payload) => dispatch(action.saveGalleryAnnotationAction(payload));
  const updateGalleryAnnotation = (payload) => dispatch(action.updateGalleryAnnotationAction(payload));
  const updateGalleryAnnotationInclInReport = (payload) => dispatch(action.updateGalleryAnnotationInclInReport(payload));
  const deleteGalleryAnnotation = (payload) => dispatch(action.deleteGalleryAnnotationAction(payload));
  const saveGalleryImageRotation = (payload) => dispatch(action.saveRotationforGalleryImagesAction(payload));
  const completeReview = (payload) => dispatch(action.completeReviewAction(payload));
  const goToHome = () => dispatch(push('/'));
  const fetchMasterAccessoryList = (payload) => dispatch(action.fetchAccessoryMasterListAction(payload));
  const showCustomErrorToast = (payload) => dispatch(action.showErrorToastAction(payload));
  const setCurrentTabAction = (payload) => dispatch(action.setCurrentTabAction(payload));
  const setAppliedFilters = (payload) => dispatch(action.setAppliedGalleryImageFilters(payload));
  const clearAnnotationToEdit = () => dispatch(action.clearAnnotationToEdit());
  const refreshTokenCompleted = () => dispatch(action.updateAccessTokenCompletedAction());
  const customTagsWithoutImage = useSelector((state) => uniq(flatten(get(state, 'adjusterReducer.customTags', []).map((image) => image.tags))));
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);

  // derived values
  const parsedFilters = !isEmpty(customTagsWithoutImage) ? Object.assign(...customTagsWithoutImage.map((tag) => ({ [tag]: appliedFilters[tag] }))) : {};
  const isUtc = !fetchingAdjusterDetailsGallery && currentTabGalleryImages.length === 0;

  // state
  const [annotationSelected, setAnnotationSelected] = useState(undefined);
  const [keyPress, setKeyPress] = useState();
  const [selectedAnnotation, setSelectedAnnotation] = useState(undefined);
  const [assets, setAssets] = useState([]);
  const [markerAsset, setMarkerAsset] = useState([]);
  const [interaction, setInteraction] = useState({
    enable: false,
    mode: {},
    type: '',
    asset: {
      assetType: 'annotation',
      data: [],
      style: {
        polygon: {
          strokeColor: '#00A3FF',
          strokeWidth: 5,
          fillOpacity: 0,
        },
      },
    },
    selectedAnnotationType: '',
  });
  const [prevAnnotationCoordinates, setPrevAnnotationCoordinates] = useState(undefined);
  const [showDelete, setShowDelete] = useState(isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS));
  const [scrollValue, setScrollValue] = useState(0);
  const [accessoryPanelLocation, setAccessoryPanelLocation] = useState({});
  const [annotationDescription, _setAnnotationDescription] = useState('');
  const [annotationDescPanelData, annotationDescPanelDispatch] = useReducer(annotationDescPanelReducer, annotationDescPanelConstants.initialValue);
  const [showAutomatedSummaryBuilder, setShowAutomatedSummaryBuilder] = useState(false);
  const [isAutomatedSummaryMenuClicked, setIsAutomatedSummaryMenuClicked] = useState(false);

  const annotationDescriptionRef = useRef(annotationDescription);
  const prevAssets = useRef();
  const currentAssets = useRef();
  const chosenAccessory = useRef();
  const accessoryCreation = useRef();
  const selectedAnnotationRef = useRef();
  const wrapperRef = useRef();
  const selectedBoxAnnotation = useRef();
  const gallaryAnnotationsRef = useRef([]);

  // utility hooks
  const styles = useStyles({ accessoryPanelLocation });

  // derived values
  const selectedGalleryImages = filteredGalleryImages.filter((image) => image.urn === selectedImage);
  const imageMetadata = get(selectedGalleryImages, [0, 'metadata']);
  const imageOrientation = get(selectedGalleryImages, [0, 'orientation'], 0);
  const MAPVIEWER_MAX_ZOOM = useSelector((state) => {
    const groups = get(state, 'adjusterReducer.assessAppSettings.groups', []);
    const zoomGroup = groups.find((group) => group.groupName === 'ZOOM_LEVEL');
    return parseFloat(get(zoomGroup, 'settings.MAX_ZOOM_LEVEL', DEFAULT_MAX_ZOOM));
  });
  const maxZoom = get(imageMetadata, ['zoom_range', 'maximum_zoom_level'], MAPVIEWER_MAX_ZOOM);

  currentAssets.current = cloneDeep(assets);

  const defaultView = {
    lonLat: { lat: 0, lon: 0 },
    zoom: 1,
    rotation: imageOrientation,
  };

  const [view, setView] = useState(defaultView);
  const [localView, setLocalView] = useState(defaultView);
  const [showExportImageryPanel, setShowExportImageryPanel] = useState(false);
  const [showReportPanel, setShowReportPanel] = useState(false);
  const [showShareReportPanel, setShowShareReportPanel] = useState(false);

  const setAnnotationDescription = (desc) => {
    annotationDescriptionRef.current = desc;
    _setAnnotationDescription(desc);
  };
  const moveAnnotationDescPanel = (annotation) => {
    if (!annotation || !mapViewerRef.current || !wrapperRef.current) return;
    const annotationCoords = get(annotation, 'geometries[0].coordinates[0]', []);
    const rotation = get(mapViewerRef.current, 'localState.view.rotation', view.rotation);
    const topRightCoordinate = getRightMostCoordinate(annotationCoords, rotation);
    let position = mapViewerRef.current.project(topRightCoordinate);
    const isDescPanelVisibleH = isCompleteVisibleH(position.x + 20, wrapperRef);
    if (!isDescPanelVisibleH) {
      const topLeftCoordinate = getLeftMostCoordinate(annotationCoords, rotation);
      position = mapViewerRef.current.project(topLeftCoordinate);
    }
    const isDescPanelVisibleV = isCompleteVisibleV(position.y + 20, wrapperRef);
    let top = position.y + (isDescPanelVisibleV ? 20 : (-annotationDescPanelConstants.HEIGHT));
    let left = position.x + (isDescPanelVisibleH ? 20 : (-annotationDescPanelConstants.WIDTH));
    const { offsetWidth, offsetHeight } = wrapperRef.current;
    if (top < 0) top = 0;
    if (left < 0) left = 0;
    if (top > (offsetHeight - annotationDescPanelConstants.HEIGHT)) top = offsetHeight - annotationDescPanelConstants.HEIGHT;
    if (left > (offsetWidth - annotationDescPanelConstants.WIDTH)) left = offsetWidth - annotationDescPanelConstants.WIDTH;
    annotationDescPanelDispatch({
      type: annotationDescPanelConstants.POSITION,
      top,
      left,
    });
  };

  const showAnnotationDesc = (annotation) => {
    if (isEmpty(gallaryAnnotationsRef.current)) return;
    const annotationId = get(annotation, 'properties.annotationId');
    const { includeInReport = false, comment = '' } = gallaryAnnotationsRef.current.find(
      (ann) => get(ann, 'annotationId') === annotationId,
    );
    selectedBoxAnnotation.current = annotation;
    moveAnnotationDescPanel(annotation);
    annotationDescPanelDispatch({
      annotation,
      type: annotationDescPanelConstants.ALL,
      show: true,
      includeInReport,
    });
    setAnnotationDescription(comment);
  };

  const hideAnnotationDesc = () => {
    selectedBoxAnnotation.current = null;
    annotationDescPanelDispatch({
      type: annotationDescPanelConstants.CLEAR,
    });
    clearAnnotationToEdit();
  };

  const handleSummaryBuilderSelect = () => {
    setShowAutomatedSummaryBuilder(true);
    setIsAutomatedSummaryMenuClicked(true);
  };

  const handleSummaryBuilderClose = () => {
    setShowAutomatedSummaryBuilder(false);
    setIsAutomatedSummaryMenuClicked(false);
  };

  useEffect(() => {
    if (annotationDescPanelData.show && selectedBoxAnnotation.current) {
      moveAnnotationDescPanel(selectedBoxAnnotation.current);
    }
  }, [localView]);

  const getMarkerFromAsset = (annotationId) => {
    const markers = get(markerAsset, '0.data', []);
    return markers.find((tMarker) => tMarker.id === annotationId);
  };

  const hideMarker = (annotationId) => {
    const marker = getMarkerFromAsset(annotationId);
    if (marker && marker.markerReference) {
      marker.markerReference.getElement().style.display = 'none';
    }
  };

  const showMarker = (annotationId) => {
    const marker = getMarkerFromAsset(annotationId);
    if (marker && marker.markerReference) {
      marker.markerReference.getElement().style.display = 'block';
    }
  };

  const addMarker = ({ id: annotationId }, coordinates) => {
    const el = document.createElement('div');
    el.setAttribute('id', annotationId);
    el.className = annotationId;
    const width = 20;
    const height = 25;
    el.style.backgroundImage = `url(${notesIcon})`;
    el.style.width = `${width}px`;
    el.style.height = `${height}px`;
    el.style.backgroundSize = '100%';
    const marker = {
      id: annotationId,
      markerOptions: {
        element: el,
        offset: [12, 10],
      },
      coordinates,
    };
    return marker;
  };

  useEffect(() => {
    const { imageId, annotationId } = createdAnnotationToEdit;
    if (enableAnnotationDescription && imageId && imageId === selectedImage) {
      assets.forEach((asset) => {
        if (asset.assetType === 'annotation') {
          const annotation = asset.data.find((_annotation) => _annotation.id === annotationId);
          setSelectedAnnotation({
            assetId: asset.uuid,
            annotationId,
            annotation,
          });
          setPrevAnnotationCoordinates(cloneDeep(get(annotation, 'geometries[0].coordinates', [])));
          setInteraction({
            ...interaction,
            type: 'editing',
            mode: { move: true, edit: true, rotate: true },
          });
          showAnnotationDesc(annotation);
        }
      });
    }
  }, [assets]);

  useEffect(() => {
    setView({
      ...(view || defaultView),
      rotation: imageOrientation,
    });
  }, [imageOrientation]);

  const handleKeyUp = (e) => {
    setKeyPress(e);
  };

  const resetToTrackMode = () => {
    setInteraction({
      ...interaction,
      enable: true,
      type: 'tracking',
      selectedAnnotationType: '',
      asset: { ...interaction.asset, data: [] },
    });
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
      setShowDelete(true);
    }
    setAnnotationSelected(undefined);
    setSelectedAnnotation(undefined);
  };

  const handleFitToWindow = () => {
    if (imageMetadata && mapViewerRef.current) {
      mapViewerRef.current.fitImageToWindow({ image: imageMetadata });
    }
  };
  const handleFullSize = () => {
    if (imageMetadata && mapViewerRef.current) {
      mapViewerRef.current.oneToOneFit({ image: imageMetadata });
    }
  };

  const resetUnsavedAssets = () => {
    if (prevAssets.current && prevAssets.current.length) {
      setAssets(cloneDeep(prevAssets.current));
    }
  };

  const prepareDeleteGalleryAnnotation = (data) => {
    const boxAnnotations = gallaryAnnotations.filter((a) => get(a, 'properties.annotationType', '') === 'box');
    const boxAnnotationId = get(boxAnnotations, '0.properties.annotationId', '');
    const selectedAnnotationType = get(selectedAnnotation, 'annotation.properties.annotationType', '');
    const selectedAnnotationId = get(selectedAnnotation, 'annotation.properties.annotationId', '');
    Object.assign(data, {
      deleteImageReportInclusion: boxAnnotations.length === 1
        && selectedAnnotationType === 'box'
        && selectedAnnotationId === boxAnnotationId,
    });
    deleteGalleryAnnotation(data);
    if (annotationDescPanelData.show) {
      hideAnnotationDesc();
    }
  };

  const deleteAccessory = () => {
    resetUnsavedAssets();
    resetToTrackMode();
    if (!accessoryCreation.current) {
      const { annotationId } = selectedAnnotation;
      prepareDeleteGalleryAnnotation({
        orderId, imageId: selectedImage, annotationId, isAccessory: true, selectedStructureId,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    if (enableGroundAccessory) {
      if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
        fetchMasterAccessoryList('wall');
      }
    }
    return () => {
      setSelectedImage(undefined);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (isUtc && assets.length > 0) setAssets([]);
  }, [isUtc]);

  useEffect(() => {
    if (annotationDescPanelData.show) {
      hideAnnotationDesc();
    }
    if (currentTab === TABS.GALLERY && !isEmpty(markerAsset)) {
      setMarkerAsset([]);
    }
  }, [selectedImage, currentTab]);

  useEffect(() => {
    const galleryList = document.getElementById('galleryList');
    if (galleryList) {
      galleryList.scrollTo(0, scrollValue);
    }
  }, [scrollValue]);

  useEffect(() => {
    const annotationType = get(selectedAnnotation, 'annotation.properties.annotationType', '');
    if (keyPress && !isUtc) {
      const { activeElement } = document;
      // check if current focused element is any input field
      if (activeElement && INPUTS.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
        // if current focused element is input then return so that image doesn't change on arrow press
        return;
      }
      if (annotationType === 'point') {
        return;
      }
      const galleryImagesUrn = filteredGalleryImages.map((image) => image.urn);
      const selectedImageIndex = galleryImagesUrn.indexOf(selectedImage);
      if (selectedImageIndex >= 0) {
        if (keyPress.keyCode === 37) {
          if (selectedImageIndex === 0) return;
          setSelectedImage(filteredGalleryImages[selectedImageIndex - 1].urn);
        } else if (keyPress.keyCode === 39) {
          if (selectedImageIndex >= (filteredGalleryImages.length - 1)) return;
          setSelectedImage(filteredGalleryImages[selectedImageIndex + 1].urn);
        }
      }
    }
    // eslint-disable-next-line
  }, [keyPress]);

  useEffect(() => {
    setMarkerAsset([]);
    if (selectedImage) {
      const galleryImagesUrn = filteredGalleryImages.map((image) => image.urn);
      const selectedImageIndex = galleryImagesUrn.indexOf(selectedImage);

      if (keyPress && (keyPress.keyCode === 37 || keyPress.keyCode === 39)) {
        setKeyPress(null);
        // Find the current row in which the image is selected, considering 4 images per row.
        const rowIndex = Math.trunc(selectedImageIndex / 4);
        const galleryList = document.getElementById('galleryList');
        let scrolledPosition = null;
        if (galleryList) {
          scrolledPosition = galleryList.scrollTop;
        }
        /**
         * scroll position should be on the top, if the row index is 0
         * scrolling to 140px(image height + top empty space), if the focus is on the first row
         * For other rows calculating the scroll position by (rowIndex * imageHeight) + top empty space
         */
        let newScrollValue = 0;
        if (rowIndex === 0) {
          setScrollValue(newScrollValue);
        } else if (rowIndex === 1) {
          newScrollValue = 140;
          setScrollValue(newScrollValue);
        } else {
          newScrollValue = ((rowIndex - 1) * 80) + 140;
          setScrollValue(newScrollValue);
        }

        // To scroll back to the actual row on keypress, If user has manually scrolled the list to some other position
        if (scrolledPosition !== scrollValue) {
          if (scrollValue === newScrollValue) {
            setScrollValue(newScrollValue + 1);
          } else {
            setScrollValue(newScrollValue);
          }
        }
      }

      if (!imageMetadata) {
        fetchImageMetadata(selectedImage);
      }
      resetToTrackMode();
    }
  }, [selectedImage]);

  useEffect(() => {
    if (!imageMetadata) return;
    const imageUrl = `${ASSESS_IMAGES_API_ENDPOINT}/${selectedImage}/tiles/{z}/{x}/{y}?format=IMAGE_FORMAT_JPEG_PNG&${getAuthParam(authToken)}`;
    setAssets([
      {
        uuid: uuidv4(),
        assetType: 'mosaic',
        tileUrls: [imageUrl],
      },
      {
        assetType: 'annotation',
        data: [],
        style: {
          polygon: {
            strokeColor: '#00A3FF',
            strokeWidth: 5,
            fillOpacity: 0,
          },
        },
      },
    ]);
    displayGalleryAnnotation({ orderId, imageId: selectedImage });
    handleFitToWindow();
  }, [imageMetadata, authToken]);

  useEffect(() => {
    if (deleteStatus) {
      resetToTrackMode();
    }
  }, [deleteStatus]);

  useEffect(() => {
    if ((galleryImages && !selectedImage)) {
      setAppliedFilters(parsedFilters);
    }
  }, [galleryImages, currentTab]);

  useEffect(() => {
    gallaryAnnotationsRef.current = gallaryAnnotations;
    const allowedAnnotationTypes = ['Polygon'];
    if (enableGroundAccessory) {
      allowedAnnotationTypes.push('Point');
    }
    const anotationAsset = assets.filter((asset) => asset.assetType === 'annotation');
    const allowedAnnotations = gallaryAnnotations.filter((annotation) => allowedAnnotationTypes.includes(get(annotation, ['geometry', 'type'])));
    // on box edit, if includeInReport is toggle'd stop re-creating box annotation assets
    if (
      interaction.type === 'editing'
        && allowedAnnotations.length === get(anotationAsset, '[0].data', []).length
        && getIntersection(allowedAnnotations, get(anotationAsset, '[0].data', []), 'annotationId', 'properties.annotationId').length
        === allowedAnnotations.length
    ) {
      return;
    }
    const markerAssetData = [];
    const anotationAssetData = allowedAnnotations.map((annotation) => {
      const annotationType = get(annotation, ['properties', 'annotationType'], 'point');
      const mappedAnnotation = Object.assign(annotation, {
        id: annotation.annotationId,
        properties: {
          ...annotation.properties,
          cursorOnTrack: 'pointer',
          annotationId: annotation.annotationId,
          annotationType,
          comment: annotation.comment,
          includeInReport: annotation.includeInReport,
        },
        style: annotationType === 'point' ? {
          point: {
            fillColor: '#fff',
            strokeColor: '#2274F7',
            strokeWidth: 10,
            radius: 4,
          },
        } : {},
      });
      if (!isEmpty(get(annotation, 'comment')) && enableAnnotationDescription) {
        markerAssetData.push(addMarker(mappedAnnotation, getBoxAnnotationDescIconCoordinates(mappedAnnotation)));
      }
      return mappedAnnotation;
    });
    const newAssets = assets.map((asset) => {
      if (asset.assetType === 'annotation') {
        return getMapviewerAsset({
          ...asset,
          data: anotationAssetData,
        }, showMapviewerError);
      }
      return getMapviewerAsset(asset, showMapviewerError);
    }).filter((asset) => asset != null);
    setAssets(newAssets);
    const newMarkerAsset = getMapviewerAsset({
      assetType: 'marker',
      uuid: 'assess-marker',
      data: markerAssetData,
    }, showMapviewerError);
    setMarkerAsset([newMarkerAsset]);
    prevAssets.current = cloneDeep(newAssets);
  }, [gallaryAnnotations]);

  const annotationData = (type, coordinates, annotationType, properties = {}, comment = '') => ({
    annotations: [
      {
        geometry: {
          type,
          coordinates,
        },
        properties: {
          isNonDrone: true,
          annotationType,
          ...properties,
        },
        comment,
        includeInReport: true,
      },
    ],
  });

  const savePointAnnotation = (pointAnnotation, type = 'create') => {
    const pointAnnotationAccessories = {
      accessories: [{
        name: chosenAccessory.current,
      }],
    };
    const pointAnnotationId = get(pointAnnotation, 'properties.annotationId', '');
    const pointAnnotationGeometries = get(pointAnnotation, 'geometries', '');
    const newAssets = currentAssets.current.map((asset) => {
      if (asset.assetType === 'annotation') {
        const data = asset.data.map((tAnnotation) => {
          if (tAnnotation.id === pointAnnotationId) {
            return {
              ...tAnnotation,
              geometries: pointAnnotationGeometries,
              properties: {
                ...tAnnotation.properties,
                ...pointAnnotationAccessories,
              },
            };
          }
          return tAnnotation;
        });
        return getMapviewerAsset({ ...asset, data }, showMapviewerError);
      }
      return asset;
    }).filter((asset) => asset != null);
    setAssets(newAssets);

    const geometry = get(pointAnnotation, 'geometries.0');
    const annotationType = get(pointAnnotation, 'properties.annotationType', '');
    if (geometry) {
      if (type === 'create') {
        const isImageIncluded = reportIncludedImages.some((x) => x === selectedImage);
        saveGalleryAnnotation({
          orderId,
          imageId: selectedImage,
          data: annotationData('Point', geometry.coordinates, annotationType, pointAnnotationAccessories),
          isImageIncluded,
          isAccessory: true,
          annotationType,
          selectedStructureId,
        });
      } else {
        const data = {
          annotation: {
            geometry,
            properties: {
              isNonDrone: true,
              annotationType,
              ...pointAnnotationAccessories,
            },
          },
        };
        updateGalleryAnnotation({
          orderId,
          imageId:
            selectedImage,
          annotationId:
            pointAnnotationId,
          data,
          isAccessory: true,
          selectedStructureId,
        });
      }
    }
  };

  const setChosenAccessory = (accessory) => {
    chosenAccessory.current = accessory;
  };

  const handleMovePoint = (data) => {
    const geometry = get(data, 'annotation', {});
    if (isEmpty(geometry)) return;
    const geometries = get(geometry, ['geometries', 0]);
    const accessoryLocation = mapViewerRef.current.project(geometries.coordinates);
    setAccessoryPanelLocation({
      top: accessoryLocation.y - 20,
      left: accessoryLocation.x + 20,
    });
    if (data.state === 'completed') {
      if (chosenAccessory.current) {
        if (accessoryCreation.current) {
          savePointAnnotation(geometry, 'create');
          accessoryCreation.current = false;
        } else {
          savePointAnnotation(geometry, 'update');
        }
      } else {
        showCustomErrorToast({ message: 'imagePreview.pleaseChooseAccessoryName' });
        resetUnsavedAssets();
      }
      resetToTrackMode();
    }
  };

  const startPointEditInteraction = (pointAnnotation) => {
    chosenAccessory.current = get(pointAnnotation, 'properties.accessories.0.name', '');
    setInteraction({
      ...interaction,
      assetId: pointAnnotation.assetId,
      annotation: pointAnnotation.annotation,
      handler: handleMovePoint,
      selectedAnnotationType: t('imagepreview.toolbar.tagAccessory'),
      editStyle: {
        point: {
          fillColor: '#2274F7',
          strokeColor: '#fff',
          strokeWidth: 10,
          radius: 4,
        },
      },
      type: 'editing',
      mode: { edit: true, move: true, rotate: true },
    });
  };

  const startPointViewOnlyInteraction = (pointAnnotation) => {
    chosenAccessory.current = get(pointAnnotation, 'properties.accessories.0.name', '');
    const geometries = get(pointAnnotation, ['geometries', 0]);
    const accessoryLocation = mapViewerRef.current.project(geometries.coordinates);
    setAccessoryPanelLocation({
      top: accessoryLocation.y - 20,
      left: accessoryLocation.x + 20,
    });
  };

  useEffect(() => {
    if (get(selectedAnnotation, 'annotation.properties.annotationType', '') === 'point' && accessoryCreation.current) {
      if (!authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
        startPointViewOnlyInteraction(selectedAnnotation);
      } else {
        startPointEditInteraction(selectedAnnotation);
      }
    }
    selectedAnnotationRef.current = selectedAnnotation;
  }, [selectedAnnotation]);

  const handlePointAnnotation = (geometry) => {
    const annotationObj = cloneDeep(geometry);
    const pointAnnotation = cloneDeep({ annotation: annotationObj, annotationId: geometry.id, assetId: null });
    prevAssets.current = cloneDeep(assets);
    const newAssets = assets.map((asset) => {
      if (asset.assetType === 'annotation') {
        const annotationAsset = getMapviewerAsset({
          ...asset,
          data: [
            ...asset.data,
            {
              ...annotationObj,
              properties: {
                ...annotationObj.properties,
                cursorOnTrack: 'pointer',
                annotationId: pointAnnotation.annotationId,
              },
              style: {
                point: {
                  fillColor: '#fff',
                  strokeColor: '#2274F7',
                  strokeWidth: 10,
                  radius: 4,
                },
              },
            },
          ],
        }, showMapviewerError);
        pointAnnotation.assetId = annotationAsset.uuid;
        return annotationAsset;
      }
      return asset;
    }).filter((asset) => asset != null);
    setAssets(newAssets);
    accessoryCreation.current = true;
    setSelectedAnnotation(pointAnnotation);
    const geometries = get(geometry, ['geometries', 0]);
    const accessoryLocation = mapViewerRef.current.project(geometries.coordinates);
    setAccessoryPanelLocation({
      top: accessoryLocation.y - 20,
      left: accessoryLocation.x + 20,
    });
  };

  const handleCreateAnnotation = (geometry) => {
    const newAssets = assets.map((asset) => {
      if (asset.assetType === 'annotation') {
        return getMapviewerAsset({
          ...asset,
          data: [
            ...asset.data,
            geometry,
          ],
        }, showMapviewerError);
      }
      return asset;
    }).filter((asset) => asset != null);
    setAssets(newAssets);

    const geometries = get(geometry, ['geometries', 0]);
    const annotationType = get(geometry, ['properties', 'annotationType'], '');
    if (geometries) {
      if (annotationSelected === 'Box') {
        const isImageIncluded = reportIncludedImages.some((x) => x === selectedImage);
        saveGalleryAnnotation({
          orderId,
          imageId: selectedImage,
          data: annotationData('Polygon', geometries.coordinates, annotationType),
          isImageIncluded,
          annotationType,
          selectedStructureId,
        });
      }
    }
  };

  const handleUpdateAnnotation = ({ annotation, description }) => {
    const { geometries, properties: { annotationId } } = annotation;
    const geometry = get(geometries, ['0']);
    const data = {
      annotation: {
        geometry,
        ...(description && { comment: description }),
      },
    };
    updateGalleryAnnotation({
      orderId, imageId: selectedImage, annotationId, data, selectedStructureId,
    });
    hideAnnotationDesc();
    resetToTrackMode();
    // display the marker(notes icon) to this annotation if notes is not empty after update
    if (!isEmpty(get(annotation, 'comment')) && enableAnnotationDescription) {
      showMarker(annotation.id);
    }
  };

  const handleUpdateBoxAnnotationInclInReport = ({ annotation, includeInReport }) => {
    const { geometries, properties: { annotationId } } = annotation;
    const geometry = get(geometries, ['0']);
    const data = {
      annotation: {
        geometry,
        includeInReport,
      },
    };
    updateGalleryAnnotationInclInReport({
      orderId, imageId: selectedImage, annotationId, data, selectedStructureId,
    });
    annotationDescPanelDispatch({
      type: annotationDescPanelConstants.ALL,
      includeInReport,
    });
  };

  const handleAnnotationMapviewerCallback = (data) => {
    const {
      state, geometry, annotation, dragEnd,
    } = data;
    // disable edit/delete for annotation for completed orders
    if (orderCompleted) return;
    if (state === 'tracked') {
      if (!isEmpty(annotation.annotation)) {
        if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)
          && !isEmpty(selectedAnnotationRef.current) && annotation.annotation !== selectedAnnotationRef.current
          && annotation.annotation.properties.annotationType !== 'box') {
          setSelectedAnnotation(undefined);
          showMarker(selectedBoxAnnotation.current.id);
          hideAnnotationDesc();
        } else {
          const { annotationId, annotationType } = annotation.annotation.properties;
          setSelectedAnnotation({ ...annotation, annotationId });
          setPrevAnnotationCoordinates(cloneDeep(get(annotation, 'annotation.geometries[0].coordinates', [])));
          if (annotationType === 'box' && annotationId) {
            if (authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
              setInteraction({
                ...interaction,
                type: 'editing',
                mode: { move: true, edit: true, rotate: true },
              });
            } else if (!isEmpty(selectedBoxAnnotation.current) && annotation.annotation !== selectedBoxAnnotation.current) {
              showMarker(selectedBoxAnnotation.current.id);
              hideAnnotationDesc();
            }
            // remove the marker from annotaiton when in edit mode.
            hideMarker(annotationId);
            showAnnotationDesc(annotation.annotation);
          } else if (annotationType === 'point' && annotationId) {
            prevAssets.current = currentAssets.current;
            if (!authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)) {
              startPointViewOnlyInteraction(annotation.annotation);
            } else {
              startPointEditInteraction(annotation.annotation);
            }
          }
        }
      } else {
        setSelectedAnnotation(undefined);
        if (selectedBoxAnnotation.current) {
          showMarker(selectedBoxAnnotation.current.id);
          hideAnnotationDesc();
        }
      }
    }
    if (state === 'in progress' && dragEnd) {
      moveAnnotationDescPanel(annotation);
      const annotationId = annotation.id;
      const marker = getMarkerFromAsset(annotationId);
      if (marker && marker.markerReference) {
        marker.markerReference.setLngLat(getBoxAnnotationDescIconCoordinates(annotation));
      }
    }
    if (state === 'completed') {
      switch (interaction.type) {
        case 'editing': {
          resetToTrackMode();
          const newAnnotationCoordinates = get(annotation, 'geometries[0].coordinates', []);
          const description = get(annotation, 'properties.comment', '');
          if (
            !isEqual(prevAnnotationCoordinates, newAnnotationCoordinates)
            || annotationDescriptionRef.current !== description
          ) {
            handleUpdateAnnotation({ annotation, description: annotationDescriptionRef.current });
          }
          setPrevAnnotationCoordinates(undefined);
          showMarker(annotation.id);
          hideAnnotationDesc();
          break;
        }
        case 'drawBox':
          resetToTrackMode();
          handleCreateAnnotation(geometry);
          break;
        case 'drawPoint':
          handlePointAnnotation(geometry);
          break;
        default:
          break;
      }
    }
  };
  const updateLocalView = (updatedView) => {
    setLocalView({ ...updatedView });
  };
  const setDebounceLocalView = debounce(updateLocalView, 250);

  const onMapViewUpdated = (updatedView) => {
    setDebounceLocalView(updatedView);
    refreshTokenCompleted();
  };

  const handleOnMapReady = () => {
    setInteraction({
      ...interaction,
      enable: (!!authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS))
        || (!!authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)),
      type: 'tracking',
      asset: { ...interaction.asset, data: [] },
    });
  };

  const onMapViewerError = (error, errorLocation) => {
    logger('error', `Error occurred in ${errorLocation}: `, get(error, 'message', error));
    showCustomErrorToast({ message: 'adjuster.mapViewerError', toastAutoHideDuration: null });
  };

  const tagAccessoryEditing = (interaction.type === 'editing'
    && interaction.selectedAnnotationType === t('imagepreview.toolbar.tagAccessory'))
    || (!authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_DECISIONS)
      && get(selectedAnnotation, 'annotation.properties.annotationType', '') === 'point');

  const imagePreviewerToolbarProps = {
    orderId,
    isGroundCaptured: !isUtc,
    showCompass: isAssessLite && currentTab !== TABS.GALLERY,
    imageOrientation,
    completeAndExit: () => {
      completeReview(orderId);
    },
    exitOnly: () => {
      goToHome();
    },
    exportImagery: () => {
      setShowExportImageryPanel(true);
    },
    generateReport: () => {
      setShowReportPanel(true);
    },
    shareReport: () => {
      setShowShareReportPanel(true);
    },
    handleRotateCompass: (rotation) => {
      setView({ ...localView, rotation });
    },
    handleRotate: () => {
      const newOrientation = transformOrientation(imageOrientation - 90);
      // make orientation compatible with reports generation logic
      const mapping = {
        0: 0,
        90: 90,
        180: -180,
        270: -90,
        360: 0,
      };
      setView({ ...localView, rotation: newOrientation });
      const payload = {
        orderId, image: selectedImage, data: { orientation: mapping[newOrientation] },
      };
      saveGalleryImageRotation(payload);
    },
    handleZoomIn: () => {
      if (localView.zoom < maxZoom && !tagAccessoryEditing) setView({ ...localView, zoom: localView.zoom + 1 });
    },
    handleZoomOut: () => {
      if (localView.zoom > 0 && !tagAccessoryEditing) { setView({ ...localView, zoom: localView.zoom - 1 }); }
    },
    handleFitToWindow,
    handleFullSize,
    handleDrawAnnotation: (selectedAnnotationType, drawType) => {
      setInteraction({
        ...interaction,
        enable: true,
        type: drawType,
        selectedAnnotationType,
        properties: {
          cursorStyle: 'crosshair',
        },
      });
    },
    handleDelete: () => {
      const { annotationId } = selectedAnnotation;
      const markers = get(markerAsset, '0.data', []);
      if (markers.length > 0) {
        setMarkerAsset([{
          ...markerAsset[0],
          data: markers.filter((marker) => (marker.id !== annotationId)),
        }]);
      }
      prepareDeleteGalleryAnnotation({
        orderId, imageId: selectedImage, annotationId, selectedStructureId,
      });
      setShowDelete(false);
    },
    selectedAnnotationType: interaction.selectedAnnotationType,
    showAccessory: enableGroundAccessory,
    disableAccessory: get(selectedAnnotation, 'annotation.properties.annotationType', 'point') !== 'point',
    setAnnotationSelected,
    showDelete: showDelete && (get(selectedAnnotation, 'annotation.properties.annotationType', '') === 'box'),
    resetToTrackMode,
    resetUnsavedAssets,
    onSummaryBuilderSelected: handleSummaryBuilderSelect,
  };
  let interactionProps = {
    enable: interaction.enable,
    type: interaction.type,
    asset: interaction.asset,
    handler: handleAnnotationMapviewerCallback,
  };
  if (interaction.type === 'editing' && selectedAnnotation) {
    const { assetId, annotation } = selectedAnnotation;
    const selectedAnnotationType = get(annotation, 'properties.annotationType', '');
    interactionProps = {
      ...interactionProps,
      mode: interaction.mode,
      assetId,
      annotation,
    };
    if (selectedAnnotationType === 'point') {
      Object.assign(interactionProps, {
        handler: interaction.handler,
        editStyle: interaction.editStyle,
      });
    }
  }

  const existingAccessory = authUtils.isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)
    ? get(selectedAnnotation, 'annotation.properties.accessories.0.name', '')
    : get(interactionProps, 'annotation.properties.accessories.0.name', '');

  const renderSwitcher = () => (
    !isSharedUrl()
      ? (
        <EVBox className={styles.viewSwitcherStyle}>
          <PhotosUITabs tab={PhotosUITab.MOSAIC} />
        </EVBox>
      )
      : <></>
  );

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      {!isUtc && !isEmpty(accessoryPanelLocation) && tagAccessoryEditing && (
        <AccessoryDropdown
          accessoryMasterList={accessories}
          accessoryPanelLocation={accessoryPanelLocation}
          onAccessorySelection={(accessory) => setChosenAccessory(accessory)}
          deleteAccessory={deleteAccessory}
          existingAccessory={existingAccessory}
          imagePreviewRef={wrapperRef}
        />
      )}
      {!isUtc && enableAnnotationDescription && !isEmpty(annotationDescPanelData) && annotationDescPanelData.show && (
        <AnnotationDescPanel
          data={annotationDescPanelData}
          updateDescription={handleUpdateAnnotation}
          updateInclInReport={handleUpdateBoxAnnotationInclInReport}
          annotationDescription={annotationDescription}
          setAnnotationDescription={setAnnotationDescription}
          setDescriptionInclude={() => {}}
        />
      )}
      {(!fetchingAdjusterDetailsGallery && !isUtc) && (
        <ErrorBoundary
          componentName="ImagePreview: MapViewer"
          alertMsg={t('imagePreview.mapViewerCrash')}
        >
          <EVMapViewer
            debug={debugMode}
            ref={mapViewerRef}
            view={view}
            assets={[
              ...assets,
              ...markerAsset,
            ]}
            maxZoom={maxZoom}
            onViewUpdated={onMapViewUpdated}
            baseMap="blank"
            bound={bounds}
            interaction={interactionProps}
            navigationControl={{
              disablePitchAndRotation: true,
              disableZoom: tagAccessoryEditing,
              disablePan: tagAccessoryEditing,
            }}
            initialConfig={{
              style: { backgroundColor: '#000' },
              restrictToWorldCoordinates: true,
              fixedPixelGeometry: true,
              mapBoxKey: MAPBOX_KEY,
            }}
            onMapReady={handleOnMapReady}
            onError={(e) => onMapViewerError(e, 'image preview')}
          />
          {isAssessPhotoViewerEnabled && renderSwitcher()}
        </ErrorBoundary>
      )}
      {isUtc && (
        <Utc currentTab={currentTab} isAssessLite={isAssessLite} setCurrentTabAction={setCurrentTabAction} />
      )}
      <ErrorBoundary
        componentName="ImagePreview: ImagePreviewerToolbar"
        alertMsg={t('imagePreview.imagePreviewerToolbarCrash')}
      >
        <ImagePreviewerToolbar {...imagePreviewerToolbarProps} />
      </ErrorBoundary>
      {showReportPanel && (
        <ErrorBoundary
          componentName="ImagePreview: ReportGenerator"
          alertMsg={t('imagePreview.reportGeneratorCrash')}
        >
          <ReportGenerator closeReportPanel={() => setShowReportPanel(false)} />
        </ErrorBoundary>
      )}
      {isDigitalDeliveryEnabled && showShareReportPanel && (
        <ErrorBoundary
          componentName="ImagePreview: ShareReportPanel"
          alertMsg={t('imagePreview.reportShareCrash')}
        >
          <ShareReportPanel closePanel={() => setShowShareReportPanel(false)} />
        </ErrorBoundary>
      )}
      {showExportImageryPanel && (
        <ErrorBoundary
          componentName="ImagePreview: ExportImageryPanel"
          alertMsg={t('imagePreview.exportImageryPanelCrash')}
        >
          <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.MANAGE_REPORTS}>
            <ExportImageryPanel closeExportImageryPanel={() => setShowExportImageryPanel(false)} />
          </EntitledComponent>
        </ErrorBoundary>
      )}
      {showAutomatedSummaryBuilder && (
        <AutomatedSummaryBuilderModal
          data-testid="automatedSummaryBuilder"
          open={showAutomatedSummaryBuilder}
          orderId={orderId}
          handleClose={() => handleSummaryBuilderClose()}
          isAutomatedSummaryMenuClicked={isAutomatedSummaryMenuClicked}
        />
      )}
      {!isUtc && enableGroundAccessory && !isNull(accessoriesSummary)
        && (
          <ErrorBoundary
            componentName="Adjuster: AccessoriesSummaryPanel"
            alertMsg={t('adjuster.accessoriesSummaryPanelCrash')}
          >
            <AccessoriesSummaryPanel
              id="accessoriesSummaryPanel"
              accessoryData={accessoriesSummary}
            />
          </ErrorBoundary>
        )}
    </div>
  );
});

ImagePreview.propTypes = {
  showMapviewerError: func,
};

ImagePreview.defaultProps = {
  showMapviewerError: () => {
    // This is an empty block
  },
};

export default ImagePreview;
